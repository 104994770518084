/* eslint-disable */
import React, { useState , useEffect } from 'react';
import EditVideoitem from '../parts/videopage/EditVideoitem.js';
import Typography from '@material-ui/core/Typography';
import Age from '../functions/Age.js';
import Amplify,{ Cache, API, graphqlOperation } from 'aws-amplify';
import { Link } from 'react-router-dom';
import * as utils from '../functions/util.js';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import getUserProfile from '../functions/getUserProfile.js';
import PlaylistItem from '../parts/Playlist/PlaylistItem.js';
import {withRouter} from 'react-router-dom'
import Wrap from '../functions/Wrap.js';
import '../parts/Playlist/PlaylistItem.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

 var isIE = /*@cc_on!@*/ false || !!document.documentMode;
  // Edge 20+
  var isEdge = !isIE && !!window.StyleMedia;
  // Chrome 1 - 71
  var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
  const isFirefox = typeof InstallTrigger !== 'undefined';


const styles = {
  form: {
    margin: 30,
    color: '#ffce0a'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
      color: '#ccc',
    backgroundColor: '#272727',
    border: '2px solid #666',
   // boxShadow: shadows[5],
    padding: 20,
    width: '50%',
    height: 300,
  },
  dropzone: {
    position: 'relative',
    width: '100%',
    minHeight: 50,
    backgroundColor: '#272727;',
    border: '1px solid #ccc',
    cursor: 'pointer',
    boxSizing: 'border-box'
  },
  descBox: {
    color: '#ccc',
    backgroundColor: '#272727;',
    border: '1px solid #ccc',
    width: '100%',
  },
  margin: {
    margin: 5,
  },
  extendedIcon: {
    marginRight: 5,
  },
}
async function fetchData(searchQuery, paramKey, param) {
   const searchResult = await API.graphql(
        graphqlOperation(searchQuery, { paramKey: param })
      );
    return searchResult;
}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function authorPlaylist(props) {
  const [Videos, setVideos] = useState([]);
  const [pid, setPid] = useState();
  const [finishLoading, setFinish] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  let data, func
  console.log(props)

  const removePlaylist =()=>{

      utils.deletePlaylist(pid).then(result => {
       setOpen(false);
         setVideos( Videos.filter(video => video.id != pid) );// remove from display
      })
      .catch(err => console.log(err));
  }
  const addPlaylist=()=>{
    props.history.push('/editPlaylist')
    handleDialogClose()
  }
  const handleDelete =()=>{
      removePlaylist(Videos.id)
  }
      const handleClose = () => {
    setAnchorEl(null);
    setOpen(false)
  };
  const copyPlaylistID=(id)=>{
    navigator.clipboard.writeText(id)
   if (isChrome || isFirefox) navigator.clipboard.writeText(id)
    else if (isIE) {
      navigator.clipboard.writeText(content)

      window.clipboardData.setData('Text', id);
      document.execCommand('copy');
    }

  }
    const handleDialogClose = () => {
    setOpen(false);

  };
    const removePlaylistDiaglog = (id) => {
    setOpen(true);
    setPid(id)
  };
 const handleEdit =(id)=>{
    props.history.push('/editPlaylist?p=' + id)
  }
   useEffect(() => {
     getUserProfile("").then(function(result) {
          console.log(result)
          data =result.data.getUserProfile.playlist.items

          let VideosArray = [];
          let playlistItem = data

          for (var value of playlistItem) {
            console.log(value)
            var obj = {};
             obj.id = value.id ;
            value.title ? obj.video_title = value.title : obj.video_title ='';
            value.description ? obj.video_desc = value.description : obj.video_desc = '';
            value.createdAt ? obj.createdAt = value.createdAt : obj.createdAt = '';
           // console.log(typeof(value.thumbnail))
           console.log(value.videos.items)
           console.log(value.videoArray)
           obj.videoArray = value.videoArray;
           if(value.videos.items.length>0  ){
               if(value.videoArray) obj.firstVideo = value.videoArray[0]
               obj.poster_image_loc = value.videos.items[0].thumbnail;
               obj.videos = value.videos.items
               
           }
           else if(value.audios.items.length>0) {
            if(value.videoArray) obj.firstVideo = value.videoArray[0]
            obj.poster_image_loc = value.audios.items[0].thumbnail;
            obj.videos = value.audios.items
           }
           else{
               obj.videos=[];
           }
            VideosArray.push(obj)
          }
           setVideos(VideosArray)
         setFinish(true)
        });
   }, []);

   if(finishLoading){
    return (
     <div>
         <Typography variant="h5" gutterBottom>My Playlists</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={()=>addPlaylist()}>Add New Playlist</Button>


        {Videos.map(item =>

            <PlaylistItem
              id={item.id}
              firstVideo={item.firstVideo}
              thumbnail={item.poster_image_loc}
              title={item.video_title}
              description={item.video_desc}
              videos ={item.videos}
              videoArray={item.videoArray}
              date={utils.formatDate(item.createdAt)}
              like={true}
              favorite={true}
              redirect = {props.history.push}
              removeMe = {removePlaylist}
              haveMenu={true}
               videoMenu={[ {name:'Copy Playlist ID', func:copyPlaylistID},
                    
                            {name:'Edit', func:handleEdit},
                            {name:'Delete', func:removePlaylistDiaglog},
                      
                      ]}
            />


        )}
        <div>
          <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Delete Playlist"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
           Deleting Playlist is permanent! Please confirm this is what you want to do.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete}  variant="contained"
              size="medium"
              color="secondary">
            Yes delete
          </Button>
          <Button onClick={handleDialogClose}  variant="contained"
              size="medium"
              color="secondary">
           Cancel
          </Button>
        </DialogActions>
      </Dialog>
        </div>
        </div>
    )
   }
   else return null;
}

export default withRouter(authorPlaylist);