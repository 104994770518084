import React,{useEffect} from 'react';
import Footer from '../parts/Footer';
import HorizontalPlaylist from '../parts/HorizontalPlaylist';
//import FeatureVideos from '../parts/FeatureVideos';
import FeaturedVideos from '../parts/FeaturedVideos';
import { connect } from 'react-redux'
import {Cache } from 'aws-amplify';
import VideoCardRow from '../parts/videoCard/VideoCardRow.js';


const mapStateToProps = state => ({
  isLogged: state.isLogged,
  isMenuOpen: state.isMenuOpen,
})

function Home(props){

  useEffect(() => {
    console.log('home')
   }, []);
 
   
    return(
      <div>
        <FeaturedVideos />
        <div class="thumbnailWrapper">
         {/* <HorizontalPlaylist title = "Recommended for you" />*/}
          <VideoCardRow title = "Recently Added" func='recent' user={props.user}/>
          <VideoCardRow title = "Trending" func="trending"  user={props.user}/>
        <VideoCardRow title = "Recommended" func="recommendations"  user={props.user}/>
        </div>
       
    
      </div>
      )
      
 
};


export default connect(mapStateToProps, null)(Home);