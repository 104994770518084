/* eslint-disable */
import React, { useState, useEffect } from 'react';
import AudioCard from '../parts/AudioCard.js';
import PropTypes from 'prop-types';
import EditVideoitem from '../parts/videopage/EditVideoitem.js';
import Typography from '@material-ui/core/Typography';
import Age from '../functions/Age.js';
import Amplify, { Cache, API, graphqlOperation } from 'aws-amplify';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import * as utils from '../functions/util.js';
import RemoveIcon from '@material-ui/icons/Remove';
import Grid from '@material-ui/core/Grid';
import queryString from 'query-string'
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import SortDateAlpha from '../parts/widgets/SortDateAlpha.js';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import PersonIcon from '@material-ui/icons/Person';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


import '../style/VideoCard.css';

var isIE = /*@cc_on!@*/ false || !!document.documentMode;
  // Edge 20+
var isEdge = !isIE && !!window.StyleMedia;
  // Chrome 1 - 71
var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
const isFirefox = typeof InstallTrigger !== 'undefined';
  

        
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  
  },
}));

const styles = {
  form: {
    margin: 30,
    color: '#ffce0a'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    color: '#ccc',
    backgroundColor: '#272727',
    border: '2px solid #666',
    // boxShadow: shadows[5],
    padding: 20,
    width: '50%',
    height: 300,
  },
  dropzone: {
    position: 'relative',
    width: '100%',
    minHeight: 50,
    backgroundColor: '#272727;',
    border: '1px solid #ccc',
    cursor: 'pointer',
    boxSizing: 'border-box'
  },
  descBox: {
    color: '#ccc',
    backgroundColor: '#272727;',
    border: '1px solid #ccc',
    width: '100%',
  },
  margin: {
    margin: 5,
  },
  extendedIcon: {
    marginRight: 5,
  },
}
async function fetchData(searchQuery, paramKey, param) {
  const searchResult = await API.graphql(
    graphqlOperation(searchQuery, { paramKey: param })
  );
  return searchResult;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


function authorAudio(props) {
 // console.log(props)
  const [loadCount, setLoadCount] = React.useState(0);
  const userInfo = Cache.getItem('userInfo')
  const myID = userInfo.id
  let author =[]
  const [value, setValue] = React.useState(0);
  const [Videos, setVideos] = useState([]);
  const [processingAudios, setProcessingAudios] = useState([]);
  const [originailArray, setOriginailArray] = useState([]);
  const [finishLoading, setFinish] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [vidID, setVidID] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [authors, setAuthors] = useState(myID);
  const [authorList, setAuthorList] = useState([]);
  const [archiveOpen, setArchiveOpen] = React.useState(false);
  const [archiveAudios, setArchiveAudios] = useState([]);
  const [infoOpen, setInfoOpen] = React.useState(false);
  const [audio, setAudio] = React.useState({
    thumbnail:null,
    title:'',
    id:''
  });
  
  let data, func

  const removeVideo = () => {
    let id = vidID;
    console.log(id)
    utils.DeleteAudioByID(id).then(result => {
        setOpen(false);
        setVideos(Videos.filter(video => video.id != id)); // remove from display
      })
      .catch(err => console.log(err));
  }

    const copyVideoID=(id)=>{
      
      navigator.clipboard.writeText(id)
   if (isChrome || isFirefox) navigator.clipboard.writeText(id)
    else if (isIE) {
      window.clipboardData.setData('Text', id);
      document.execCommand('copy');
    }

  }

  const removeVideoDiaglog = (id) => {
    setOpen(true);
    setVidID(id)
  }
  const openInfoDiaglog = (id) => {
    setInfoOpen(true);
    setVidID(id)
    
  }
   const archiveDialog = (id) => {
    setArchiveOpen(true);
    setVidID(id)
  }
   const archiveVideo = () => {
    let id = vidID;
    console.log(id)
    utils.ArchiveAudioByID(id).then(result => {
        setArchiveOpen(false);
        setVideos(Videos.filter(video => video.id != id)); // remove from display
      })
      .catch(err => console.log(err));
  }
   const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
 
  
  const filterVideoByAuthor=(val)=>{
    console.log(originailArray)
    let result = originailArray.filter(v => v.primaryAuthorID == val); 
    setVideos(result)
    setRefresh(!refresh)
  }

  const sortAlpha = (arr) => {
    arr.sort((a, b) => a.video_title.localeCompare(b.video_title))
    setVideos(arr)
    setRefresh(!refresh)
  }

  const sortDate = (arr) => {
    arr.sort((a, b) => b.updatedAt.localeCompare(a.updatedAt))
    //  console.log(Videos)
    setVideos(arr)
    setRefresh(!refresh)
  }

  const handleDialogClose = () => {
    setOpen(false);
  };
   const handleInfoClose = () => {
    setInfoOpen(false);
  };
  const handleEdit = (id) => {
    props.history.push('/editAudio?v=' + id)
  }
  const handleWatch = (id) => {
    props.history.push('/watch?v=' + id)
  }
  const handleClose = () => {
    //setAnchorEl(null);
    setOpen(false)
  };
    const handleArchiveDialogClose = () => {
    setArchiveOpen(false);
  };
  const addVideo = () => {
    props.history.push('/editAudio')
  }
  const getUserInfo=async(id)=>{
    console.log(id)
    let obj = await authorList.find(o => console.log(o.value));
    
    return(obj)
  }
  const getVideos = () => {
   
    setVideos([])
    setFinish(false)
    utils.searchAudioByOwner().then(function(result) {
       console.log(result)
      let VideosArray = [];

  
      data = result.data.searchAudios.items
     console.log(data)
      let playlistItem = data

      for (var value of playlistItem) {

        var obj = {};
        obj.id = value.id;
        obj.video_title = value.title;
        obj.video_desc = value.description;
        obj.createdAt = value.createdAt;
        obj.updatedAt = value.updatedAt;
        obj.poster_image_loc = value.thumbnail
        obj.audioState = value.audioState
        obj.allowTrainerDownload = value.allowTrainerDownload
        // console.log(utils.formatDuration(value.videoDuration))
        obj.duration = utils.formatDuration(value.audioDuration)
        obj.views = value.audioViews
        obj.expirationDate=value.expirationDate;
        VideosArray.push(obj)



      }
      let activeArray = VideosArray.filter(v => v.audioState == "ACTIVE");
      console.log(VideosArray)
      setVideos(activeArray)
      sortDate(activeArray)
      setProcessingAudios(VideosArray.filter(v => v.audioState != "ACTIVE" && v.audioState != "ARCHIVED" && v.audioState != "undefined" &&  v.audioState != null))
      setArchiveAudios(VideosArray.filter(v => v.audioState == "ARCHIVED"))
      setFinish(true)
      setLoadCount(loadCount +1)
     
      setRefresh(!refresh)
    });
  }

  
  useEffect(() => {

    
    // console.log(data)
    let author = []
      setTimeout(function() {
        getVideos();
         setFinish(true)
      }.bind(this), 2000);
     utils.listUser().then(function(result) {
        const getAuthor = result.data.searchUsers.items
        let obj
        author.length = 0;
        let num = 0;

        for (let value of getAuthor) {
          obj = {}
          num++;
          obj.label = value.givenName + ' ' + value.familyName
          obj.value = value.id
          obj.thumbnail = value.profilePhotoURL
          author.push(obj)
        }
        setAuthorList(author)
   // console.log(author)
      });
   
  }, [value]);

  if (finishLoading) {
    return (
      <div class="thumbnailContentBlock">
          <Grid container sm ={12} style={{backgroundColor:"#333",height:50}}>
            <Grid item sm={5}>
              <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                <Tab  label="My Audios" {...a11yProps(0)} />
                <Tab   disabled={!finishLoading} label="Archive" {...a11yProps(1)} />
               
              </Tabs>
            </Grid>
            
            <Grid container sm={7} direction={'row'}>
            
              <Grid item>
                <Button
                size={'small'}
                variant="contained"
                color="secondary"
                onClick={()=>addVideo()}>Add New Audios</Button>
                
                </Grid>
                <Grid item style={{margin:-10}}>
                    <SortDateAlpha sortAlpha={()=>sortAlpha(Videos)} sortDate = {()=>sortDate(Videos)}/>
                </Grid>
             
              </Grid>
          
        
        </Grid>
       
        
        <TabPanel value={value} index={0} style={{backgroundColor:'black',color:'white'}}>
            {processingAudios.length>0 &&
          <div>
            <Typography variant="h6" gutterBottom>Scheduled or Processing</Typography>
          <Grid container direction='row'>

            {processingAudios.map(video =>
                <Grid item  alignItems="center" >
                        
                  <AudioCard
                    activity = {video.createdAt}
                    image = {video.poster_image_loc}
                    title = {video.video_title}
                    views = {video.views}
                    age = {Age(video.createdAt)}
                    allowTrainerDownload = {video.allowTrainerDownload}
                    duration = {video.duration}
                    imageStyle={{filter: 'grayscale(100%)'}}
                    //channel = {video.channel.channel_title}
                    id = {video.id||0}
                    videoMenu={[ {name:'Edit', func:handleEdit},
                      {name:'Copy Audio ID', func:copyVideoID},
                      {name:'Delete', func:removeVideoDiaglog},
                     
                      {name:'Archive', func: archiveDialog},
                      ]}
                       scheduledArchived = {video.expirationDate==null?false:true}
                  />
                
                  </Grid>
            )}
        
          </Grid>
          </div>
          }
            <Typography variant="h6" gutterBottom>Active</Typography>
          <Grid container direction='row'>
 
            {Videos.map(video =>
                <Grid item  alignItems="center" >
               
                  <AudioCard
                    activity = {video.createdAt}
                    image = {video.poster_image_loc}
                    title = {video.video_title}
                    views = {video.views}
                    age = {Age(video.createdAt)}
                    allowTrainerDownload = {video.allowTrainerDownload}
                    duration = {video.duration}
                    //channel = {video.channel.channel_title}
                    id = {video.id||0}
                    videoMenu={[ {name:'Edit', func:handleEdit},
                      {name:'Copy Audio ID', func:copyVideoID},
                      {name:'Delete', func:removeVideoDiaglog},
                     
                      {name:'Archive', func: archiveDialog},
                      ]}
                       scheduledArchived = {video.expirationDate==null?false:true}
                  />
                
                  </Grid>
            )}
        
          </Grid>
       </TabPanel>
       <TabPanel value={value} index={1} style={{backgroundColor:'black',color:'white'}}>
          <Grid container direction='row'>

            {archiveAudios.map(video =>
                <Grid item  alignItems="center" >
               
                  <AudioCard
                    activity = {video.createdAt}
                    image = {video.poster_image_loc}
                    title = {video.video_title}
                    views = {video.views}
                    age = {Age(video.createdAt)}
                    allowTrainerDownload = {video.allowTrainerDownload}
                    duration = {video.duration}
                    //channel = {video.channel.channel_title}
                    id = {video.id||0}
                    videoMenu={[ {name:'Edit', func:handleEdit},
                      {name:'Copy Audio ID', func:copyVideoID},
                      {name:'Delete', func:removeVideoDiaglog},
                     
                       {name:'Archive', func: archiveDialog},
                      ]}
                       scheduledArchived = {video.expirationDate==null?false:true}
                  />
                
                  </Grid>
            )}
        
          </Grid>
       </TabPanel>
          
         
          
            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle id="alert-dialog-slide-title">{"Delete Audio"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                 Deleting audio is permanent! Please confirm this is what you want to do.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={removeVideo}  variant="contained"
                    size="medium"
                    color="secondary">
                  Yes delete
                </Button>
                <Button onClick={handleDialogClose}  variant="contained"
                    size="medium"
                    color="secondary">
                 Cancel
                </Button>
              </DialogActions>
            </Dialog>
            
                 <Dialog
              open={archiveOpen}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleArchiveDialogClose}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle id="alert-dialog-slide-title">{"Archive Video"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                 Are you sure you want to archive this video?
                  Existing references within embedded players may show a deactivated message.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={archiveVideo}  variant="contained"
                    size="medium"
                    color="secondary">
                  Yes archive
                </Button>
                <Button onClick={handleArchiveDialogClose}  variant="contained"
                    size="medium"
                    color="secondary">
                 Cancel
                </Button>
              </DialogActions>
            </Dialog>
            
            
           
           
        </div>
    )
  }
  else return null;
}

export default withRouter(authorAudio);
