/* eslint-disable */
import React, { useRef, useState, useEffect, Fragment } from "react";
import { makeStyles } from '@material-ui/core/styles';
import ReactDOM from "react-dom";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import VideoPlayer from '../parts/videoPlayer/VideoPlayer.jsx'
import VerticalPlaylist from '../parts/VerticalPlaylist'
import Like from '../parts/buttons/Like';
import Favorite from '../parts/buttons/Favorite';
import More from '../parts/buttons/More';
import Share from '../parts/buttons/Share';
import Box from '@material-ui/core/Box';
import * as Queries from '../graphql/queries.js'
import Amplify, { API, graphqlOperation, Cache } from 'aws-amplify';
import * as utils from '../functions/util.js';
import queryString from 'query-string'
import { connect } from 'react-redux'
import { hideMenu } from '../action/menu'
import compose from 'recompose/compose';
import getUserProfile from '../functions/getUserProfile.js';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Icon from '@material-ui/core/Icon';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import '../parts/videoPlayer/player.css';
import PlayerPlaylist from '../parts/Playlist/PlayerPlaylist.js';
import ChannelTile from '../parts/Subscriptions/ChannelTile.js';
import PlayerKeywords from '../parts/videoPlayer/PlayerKeywords.js';
import Footer from '../parts/Footer.js';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import SadVid from '../images/iconJS/SadVid.js';
import ShareVideo from '../parts/videoShare.js';
import VideoInfo from '../parts/videoInfo.js';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import CancelIcon from '@material-ui/icons/Cancel';

import IconButton from '@material-ui/core/IconButton';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import CollectionIcon from '../images/iconJS/Collection.js';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ReactGA from 'react-ga';
import JSZip from "jszip"
import JSZipUtils from 'jszip-utils';
const trackingId = "UA-72261499-1";
ReactGA.initialize(trackingId);
ReactGA.pageview('playerpage');
//ga('send', 'pageview');
const mapDispatchToProps = dispatch => ({
  //hideMenu: () => dispatch(hideMenu()),
})
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));
const style = {
  root: {
    flexGrow: 1,
    backgroundColor: 'black',
    color: 'white',
    //maxWidth: '854px',
    padding: '12px',
    paddingTop: '0px',
  },
  gridContainer: {
    marginTop: '12px',
  },
  videoDescription: {
    maxWidth: '854px',
  },
  videocontainer: {
    //width:'2000px',
  },
  transcodingBox: {
    position: 'relative',
    display: 'inline-block',
  },

}

function Player(props) {
  const dropClass = useStyles();
   console.log(props)
  const [videoJS, setVideo] = useState(null);
  const [rerender, renderthis] = useState(false);
  const [videoNotExist, setVideoNotExist] = useState(false);
  const [isAuthor, setAuthor] = useState(false);
  const [render, setRender] = useState(false);
  const [autoplay, setAutoplay] = useState(false);
  const [havePlaylist, setPlaylist] = useState(false);
  const [shareOpen, setShareOpen] = React.useState(false);
  const [vidID, setVidID] = React.useState(props.id);
  const [infoOpen, setInfoOpen] = React.useState(false);
  const [downloading, setDownloading] = React.useState(false);
  const { classes } = props;

  const [posterExt, setPosterExt] = useState();
   const [fontsize, setFontsize] = useState(12);
  const [thisVideo, setVideoID] = useState(); //queryString.parse(props.location.search).v
  let thisplaylist, currentIndex
  //props.hideMenu;
  const textAreaRef = useRef(null);
  const [value, setValue] = React.useState('recents');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [index, setIndex] = React.useState();
  const [values, setValues] = React.useState({})
  const [transcoding, setTranscoding] = React.useState(false)
  const [archived, setArchived] = React.useState(false)
  const [seekTime, setSeekTime] = React.useState()
  const [transcriptHTML, setTranscriptHTML] = React.useState('');
  const [showTranscript, setShowTranscript] = React.useState(false)
  const [associatedList, setAssociatedList] = useState({playlist:[],channel:[]});
  let userInfo = Cache.getItem('userInfo')
  var isIE = /*@cc_on!@*/ false || !!document.documentMode;
  // Edge 20+
  var isEdge = !isIE && !!window.StyleMedia;
  // Chrome 1 - 71
  var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
  const isFirefox = typeof InstallTrigger !== 'undefined';

  var ua = navigator.userAgent.toLowerCase();
  var isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile")
  function handleChange(event, newValue) {
    setValue(newValue);
  }

  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleInfoOpen = () => {
    openInfoDiaglog()
  };

  const openInfoDiaglog = () => {
    setInfoOpen(true);
    setAnchorEl(null);
    setVidID(props.id)
  }
  const handleInfoClose = () => {

    setInfoOpen(false);
  };
  const handleDownloadClose = () => {
    setDownloading(false);
  };
  const handleDownloadToggle = () => {
    setDownloading(!downloading);
  };

 const toggleTranscript=()=>{
    console.log(showTranscript)
 
  if(showTranscript)  setShowTranscript(false)
  else setShowTranscript(true)
  }
  const copyIDtoClipboard = () => {
    navigator.clipboard.writeText(thisVideo)
    if (isChrome || isFirefox) navigator.clipboard.writeText(thisVideo)
    else if (isIE) {
      navigator.clipboard.writeText(content)

      window.clipboardData.setData('Text', thisVideo);
      document.execCommand('copy');
    }
    handleMenuClose();
  }
  const copyiConnectcodetoClipboard = () => {

    let url = `${process.env.REACT_APP_CDN_ICONNECT_URL}vco/${thisVideo}&v=video.mp4&p=poster.${posterExt}&cc=subtitle.vtt&w=720&h=404 ` //clips.sprint.com/VCO_ID&v=video.mp4&p=poster.jpg&cc=subtitle&w=720&h=404
    navigator.clipboard.writeText(url)
    if (isChrome || isFirefox) navigator.clipboard.writeText(url)
    else if (isIE) {
      navigator.clipboard.writeText(content)

      window.clipboardData.setData('Text', url);
      document.execCommand('copy');
    }
    handleMenuClose();
  }
  
  const handleTrainerDownload = () => {
    handleDownloadToggle()
     console.log(videoJS)
        event.preventDefault()
        var zip = new JSZip();
        var count = 0;
        var zipFilename = `clips_${videoJS.id}.zip`;
        var urls = [

            `${videoJS.video}`,
            `${videoJS.poster}`,
        ];
        if (videoJS.captions !== null && videoJS.captions.length>0) {
            videoJS.captions.map(c =>
                urls.push(c.src)
            )
        }
        if (videoJS.hasOwnProperty('transcript')) {
          if (videoJS.transcript !== null) {
  
           
              videoJS.transcript.url!=''?urls.push(videoJS.transcript.url):''
                 
              
             }
      }
       if (videoJS.hasOwnProperty('chapters')) {
          if (videoJS.chapters !== null&&videoJS.chapters.length>0) {
            videoJS.chapters.map(c =>
                 
                  c.file!=''?urls.push(c.file||c.src):''
                 // urls.push(c.file||c.src)
              )
                  }
  }
       console.log(urls)
        urls.forEach(function(url) { 
          { console.log(url) }
            var filename = url;
            handleDownloadClose(true)
            // loading a file and add it in a zip file
            JSZipUtils.getBinaryContent(url, function(err, data) {
                if (err) {
                    // console.log(err)
                    setAnchorEl(null);
                    handleDownloadClose(false)
                  //  throw err; // or handle the error
                }
                let flattenName = filename.split('/')
                //console.log(filename)
                let ext = flattenName[flattenName.length - 1].split('.')
                if (ext[ext.length-1]!='vtt'){
                 zip.file(flattenName[flattenName.length - 1], data, { binary: true });
                }
                else{
                  zip.file(flattenName[flattenName.length - 2] +'/'+ flattenName[flattenName.length - 1], data, { binary: true });
                }
                
                count++;
 console.log('count = ' + count)
                if (count == urls.length) {
                 
                   // console.log('download')
                    zip.generateAsync({ type: 'blob' }).then(function(content) {
                        saveAs(content, zipFilename);
                        setAnchorEl(null);
                        handleDownloadClose(false)
                    });
                }
            });
        });
    handleMenuClose();
  }
    const zoomIn = () =>{
      if(fontsize < 30) setFontsize(fontsize + 4)
    }
    
    const zoomOut = () =>{
      if(fontsize > 12) setFontsize(fontsize - 4)
    }
    const handleSourceDownload = () => {
     console.log(videoJS)
     handleDownloadToggle()

        
        var zip = new JSZip();
        var count = 0;
        var zipFilename = `clips_source_${videoJS.id}.zip`;
        let urls
        if(videoJS.id.length==12){
          urls = [
              `${videoJS.sourceVideo}`,
              `${videoJS.poster}`,
          ];
        }
        else{
            urls = [
              `${videoJS.source}`,
              `${videoJS.poster}`,
          ];
        }
        
        if (videoJS.captions !== null&&videoJS.captions.length>0) {
            videoJS.captions.map(c =>
                urls.push(c.src)
            )
        }
        if (videoJS.hasOwnProperty('transcript')) {
          if (videoJS.transcript.hasOwnProperty('url')) {
              videoJS.transcript.url!=''?urls.push(videoJS.transcript.url):''
                 
              
             }
      }
       if (videoJS.hasOwnProperty('chapters')) {
          if (videoJS.chapters !== null&&videoJS.chapters.length>0) {
            videoJS.chapters.map(c =>
                 
                  c.src!=''?urls.push(c.src):''
                 // urls.push(c.file||c.src)
              )
                  }
  }
        console.log(urls)

        urls.forEach(function(url) { { console.log(url) }
            var filename = url;
            // loading a file and add it in a zip file
            JSZipUtils.getBinaryContent(url, function(err, data) {
                if (err) {
                    // console.log(err)
                    setAnchorEl(null);
                    handleDownloadClose(false)
                  
                   // throw err; // or handle the error
                }
                let flattenName = filename.split('/')
                //console.log(filename)
                let ext = flattenName[flattenName.length - 1].split('.')
                if (ext[ext.length-1]!='vtt'){
                 zip.file(flattenName[flattenName.length - 1], data, { binary: true });
                }
                else{
                  zip.file(flattenName[flattenName.length - 2] +'/'+ flattenName[flattenName.length - 1], data, { binary: true });
                }
                count++;

                if (count == urls.length) {
                    console.log('download')
                    zip.generateAsync({ type: 'blob' }).then(function(content) {
                        saveAs(content, zipFilename);
                        setAnchorEl(null);
                        handleDownloadClose(false)
                    });
                }

            });

        });

    };
    
      function sourceFileExists(id) {
        let sourceURL = `${process.env.REACT_APP_CLOUDFRONT_URL}vco/${id}/sources/video.mp4`
        var http = new XMLHttpRequest();
        http.open('HEAD', sourceURL, false);
        http.send();
        return [http.status != 403, sourceURL];
    }

  useEffect(() => {
    const values = queryString.parse(props.location.search)
    console.log(values)
   
    setVideoID(values.v)
    let ccDefault;
   
    setValues({ ...values, playlist: values.p, index: values.index, seek: values.t, cc:ccDefault })

    if (userInfo.role != 'Viewer') setAuthor(true)

    if (typeof values.autoplay !== "undefined") setAutoplay(values.autoplay)

    if (typeof values.v !== "undefined") {
      if (values.v.length == 12 || values.v.length == 13) getVideo(values.v)
      else if (values.v.length == 16) getAudio(values.v)
    }
    else if (values.p) parsePlaylist(values.p, values.index)

    if (typeof values.index !== "undefined") setIndex(values.index)

    setSeekTime(values.t)
    return () => {
      document.title = "T-Mobile Clips"
    };

    // Google Analytics
    //Twistage GA Account - tracks page view information

    ga('send', 'pageview');
    // end Google Analytics

  }, [render]);

  const parsePlaylist = (p, index) => {
    
    setPlaylist(true)
   
    utils.searchPlaylistbyID(p).then(function(result) {
      console.log(result)

      let thisPlaylist = result.data.searchPlaylists.items[0]
      //let thisPlaylist = data.find(x => x.id === p)
      document.title = `T-Mobile Clips / ${ result.data.searchPlaylists.items[0].title}`
      let video
      console.log(thisPlaylist)
      if (thisPlaylist.videoArray) video = thisPlaylist.videoArray[index - 1]
      else video = thisPlaylist.videos.items[index - 1]
      console.log(video)
      setVideoID(video)
      if (video.length == 12 || video.length == 13) getVideo(video)
      else if (video.length == 16) getAudio(video)

    });
    /*
    getUserProfile("").then(function(result) {

      let data = result.data.getUserProfile.playlist.items
      let thisPlaylist = data.find(x => x.id === p)
      let video
      console.log(thisPlaylist)
      if (thisPlaylist.videoArray) video = thisPlaylist.videoArray[index - 1]
      else video = thisPlaylist.videos.items[index - 1]
      setVideoID(video)
      getVideo(video)
    });
    */
  }

  const createMarkup = (html) => {
    return { __html: { html } };
  }

  const renderThis = () => {
    console.log('redner this')
    setRender(!render)
  }
  const handleShareOpen = () => {
    setAnchorEl(null);
    setShareOpen(true)
  };
  const openShare = (id) => {
    setShareOpen(true)
  }
  const closeShare = (id) => {
    setShareOpen(false)
  }
  const getAudio = async(vid) => {
    console.log(`audio: ${vid}`)
    const searchVideo = `query SearchAudio {
      searchForAudio(id:"${vid}")
    }`;

    const searchResult = await API.graphql(
        graphqlOperation(searchVideo, {})
      )
      .then(data => {
        console.log(data)
        const q = queryString.parse(props.location.search)
        let obj = {};
        console.log(data.data.searchForAudio)
        const value = JSON.parse(data.data.searchForAudio)
        console.log(value)
        setValue(value)
        document.title = `T-Mobile Clips / ${value.title}`
        setVidID(value.id)
        obj.poster = value.thumbnail
        obj.id = value.id;
        obj.key = value.thumbnail
        obj.title = value.title;
        var base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
        while(base64regex.test(base64regex)){
              try {
                obj.video_desc =decodeURIComponent(value.description);
                } 
                catch (ex) {
                  obj.video_desc = value.description;
                }
         }
        console.log(obj.video_desc)
        obj.createdAt = value.createdAt;
        obj.sources = [];
        obj.autoplay = false;
        obj.controls = true;
        obj.like = value.like;
        obj.favourite = value.favourite;
        obj.keywords = value.keywords
        obj.channel = value.channels
        obj.subscribed = value.subscribed
        obj.subtitle = []
        obj.captions = []
        obj.source = value.source
        obj.duration = utils.formatDuration(value.audioDuration)
        
        if(value.hasOwnProperty("transcripts")){
          obj.transcript={};
          obj.transcript = value.transcripts[0]

          let xhr = new XMLHttpRequest();
            xhr.open('GET', obj.transcript.url);
            xhr.responseType = 'text';
            xhr.send();
            xhr.onload = function() {
            
              //obj.transcriptHTML = xhr.responseText.replace(/<\/?span[^>]*>/g,'');
              setTranscriptHTML(xhr.responseText.replace(/<\/?span[^>]*>/g,''))
            };
        }

        if (value.captions) {
          //console.log(value.captions)
          value.captions.map(caption => {
             let tmp = {}
              tmp.src = caption.file
              tmp.kind = caption.type
              tmp.language = caption.language
              tmp.label = caption.language
              obj.captions.push(tmp)
            /*
            obj = {}
            //obj.subtitle.src = caption.file
            obj.subtitle.kind = caption.type
            obj.subtitle.language = caption.language
            obj.subtitle.label = caption.language
            subtitle.push(obj)
            */
          })

        }

        let video = {}

        video.src = value.audioURL
        video.type = "audio/mp4"
       
        console.log(values)

        console.log(q.t)
        if (q.t) obj.seek = q.t

        if (value.audioState === 'TRANSCODING' || value.audioState === 'TRANSCODING-SCHEDULED') setTranscoding(true)
        if (value.videoState === "ARCHIVED") setArchived(true)
        console.log(obj)
        obj.sources.push(video)
        // console.log(obj)
         if (value.collection !== undefined) {
          console.log(value.collection)
          obj.collection = {}
          obj.collection.icon = value.collection.icon_graphic
          obj.collection.id = value.collection.collection_id
          obj.collection.title = value.collection.collection_title
          obj.collection.subtitle = value.collection.sub_collection_title
        }
        obj.ccDefault = queryString.parse(props.location.search).cc?true:false;
        setVideo(obj)

        const ext = value.thumbnail.split('.')
        // console.log(ext[ext.length-1]) 
        setPosterExt(ext[ext.length - 1])

        console.log(videoJS)
        renderthis(true)


      })
      .catch(err => {
        console.log(err)
        if (err.searchForVideo == null) setVideoNotExist(true)

      });
  }
 
  const handleEdit = () => {
    //console.log(props)
     if(vidID.length==12||vidID.length==13){
         props.history.push('/editVideo?v=' + vidID)
        }
        else if(vidID.length==16) props.history.push('/editAudio?v=' + vidID)
  }
  
    const handleCollectionLink= (id) => {
    //console.log(props)
    props.history.push('CollectionPage?id=' + id)
  }

  const getVideo = async(vid) => {
    //console.log(`video: ${vid}`)
    const searchVideo = `query SearchVideo {

  searchForAVideo(id:"${vid}")

}`;


    const searchResult = await API.graphql(
        graphqlOperation(searchVideo, {})
      )
      .then(data => {
        console.log(data)
        const q = queryString.parse(props.location.search)
        let obj = {};
        // console.log(typeof(data.data.searchForAVideo))
        const value = JSON.parse(data.data.searchForAVideo)
        console.log(value)
        setValue(value)
        document.title = `T-Mobile Clips / ${value.title}`
        
        var base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
        obj.video_desc = value.description;
      
        while(utils.testDecode( obj.video_desc)){
              try {
                obj.video_desc =decodeURIComponent( obj.video_desc);
                } 
                catch (ex) {
                  console.log('decode error')
                  obj.video_desc = value.description;
                }
         }
        console.log(obj.video_desc)

        setVidID(value.id)
        obj.poster = value.thumbnail
        obj.id = value.id;
        obj.key = value.thumbnail
        obj.title = value.title;
       
        obj.video_desc = obj.video_desc.replace(/<a /g, "<a target=_blank ");
        obj.createdAt = value.createdAt;
        obj.updatedAt = value.updatedAt
        obj.channel = value.channels
        value.channel ? obj.channel.channel_title = value.channel.title : '';
        obj.sources = [];
        obj.autoplay = false;
        obj.controls = true;
        obj.like = value.like;
        obj.favourite = value.favourite;
        obj.keywords = value.keywords
        obj.channel = value.channels
        obj.subscribed = value.subscribed
        obj.allowTrainerDownload = value.allowTrainerDownload
        obj.captions = []
        obj.video = value.videoURL
        obj.sourceVideo =value.sourceVideo
        obj.duration = value.videoDuration
        obj.chapters=[] 
       
        if(value.hasOwnProperty("transcripts")){
          obj.transcript={};
          obj.transcript = value.transcripts[0]

          let xhr = new XMLHttpRequest();
            xhr.open('GET', obj.transcript.url);
            xhr.responseType = 'text';
            xhr.send();
            xhr.onload = function() {
            
              //obj.transcriptHTML = xhr.responseText.replace(/<\/?span[^>]*>/g,'');
              setTranscriptHTML(xhr.responseText.replace(/<\/?span[^>]*>/g,''))
            };
        }
        
        
        
        /* 
          if (value.captions && value.captions.length>0) {
             obj.subtitle.src = value.captions[0].file
             obj.subtitle.kind = value.captions[0].type
             obj.subtitle.language = value.captions[0].language
             obj.subtitle.label = value.captions[0].language
          }*/
        console.log(value.captions)
        if (value.captions && value.captions.length > 0) {
          value.captions.map(caption => {
            //  console.log(caption)
            if (caption.file != '') {
              let tmp = {}
              tmp.src = caption.file
              tmp.kind = caption.type
              tmp.language = caption.language
              tmp.label = caption.language
              obj.captions.push(tmp)
            }
          })
        }
        if (value.chapters && value.chapters.length > 0) {
          value.chapters.map(chapter => {
            //  console.log(caption)
            if (chapter.file != '') {
              let tmp = {}
              tmp.src = chapter.file
              tmp.language = (chapter.language=='English'?'EN':"")
              obj.chapters.push(tmp)
            }
          })
        }
        console.log(value.collection !== undefined)

        if (value.collection !== undefined) {
          console.log(value.collection)
          obj.collection = {}
          obj.collection.icon = value.collection.icon_graphic
          obj.collection.id = value.collection.collection_id
          obj.collection.title = value.collection.collection_title
          obj.collection.subtitle = value.collection.sub_collection_title
        }


        let video = {}
        if (("streaming" in value) === true && !isAndroid) {
          if(value.streaming.length>0){
            video.src = value.streaming[0].url
            video.type = value.streaming[0].videoType
          }
          else{
            video.src = value.videoURL
            video.type = "video/mp4"
          }
        }

        else {
          video.src = value.videoURL
          video.type = "video/mp4"
        }
        console.log(q.t)
        if (q.t) obj.seek = q.t

        if (value.videoState === 'TRANSCODING' || value.videoState === 'TRANSCODING-SCHEDULED') setTranscoding(true)
        if (value.videoState === "ARCHIVED") setArchived(true)
        console.log(obj)
        obj.sources.push(video)
        console.log( window.location.search)
        

        obj.ccDefault = queryString.parse(props.location.search).cc===undefined?false:true;
        console.log(obj)
        setVideo(obj)

        const ext = value.thumbnail.split('.')
        // console.log(ext[ext.length-1]) 
        setPosterExt(ext[ext.length - 1])

        console.log(videoJS)
        renderthis(true)


      })
      .catch(err => {
        console.log(err)
        if (err.searchForVideo == null) setVideoNotExist(true)

      });
  }
  if (videoNotExist) {
    return (
      <div class="player-column deactivated">
            <div style={{height:150, width:150, display: 'block',marginLeft: 'auto', marginRight: 'auto',top: 80,position:'relative'}}>
                <SadVid /><br/><br/>
            </div>
            {console.log(value)}
            <div style={{height:150, display: 'block',marginLeft: 'auto', marginRight: 'auto',top: 150,position:'relative',textAlign:'center'}}>
            {(value=="No video found") &&
                <Typography variant="h5" component="h5" gutterBottom>
               No media could be found with the ID {thisVideo}
               </Typography>
            }
                 
            </div>
    </div>)
    //return <Typography variant="h3" component="h2" gutterBottom>This video no longer exists...</Typography>
  }
  if (archived) {
    return (
      <div class="player-column deactivated">
              <div style={{height:150, width:150, display: 'block',marginLeft: 'auto', marginRight: 'auto',top: 80,position:'relative'}}>
                <SadVid /><br/><br/>
            </div>
            <div style={{display: 'block',marginLeft: 'auto', marginRight: 'auto',top: 150,position:'relative',textAlign:'center'}}>
                <Typography variant="h5" component="h5" gutterBottom>The media with ID - {thisVideo} has been deactivated</Typography>
            </div>
    </div>)
    //return <Typography variant="h3" component="h2" gutterBottom>This video no longer exists...</Typography>
  }

  if (rerender) {
    const date = new Date(videoJS.createdAt);
    const date2 = new Date(videoJS.updatedAt)
    console.log(videoJS)
    const desc = videoJS.video_desc
    console.log(typeof(desc))
    const CreatedDate = (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
    const UpdateDate = (date2.getMonth() + 1) + '/' + date2.getDate() + '/' + date2.getFullYear();
    return (
    
      <div class="player-page-wrapper">
      <Backdrop
      className={dropClass.backdrop}
      open={downloading}
     
    >
        <CircularProgress color="inherit" />
        </Backdrop>
      {(videoJS.collection!==undefined && videoJS.collection.title !== 'None')   &&
       
      <div style={{width:855,height:30,display:'flex',marginBottom:10,clear:'right'}}>
     
       <span style={{borderBottom:'1px solid #E20074',width:'100%',display:'inline-flex'}}><img src={videoJS.collection.icon}/><h5 style={{marginLeft:10}} ><span style={{cursor:"pointer"}} onClick={()=>handleCollectionLink(videoJS.collection.id)}>{videoJS.collection.title}</span> : {videoJS.collection.subtitle}</h5><CollectionIcon style={{left:1100,position:'absolute'}}/></span>
       
      </div>
      
      }
      
      <div class="player-column" style={{float:'left'}}>
        
        {!transcoding &&  <VideoPlayer vid={{videoJS}} autoplay={autoplay} value={values} toggleFunc={toggleTranscript}/>}
        {transcoding && 
         <div class="videoTranscodingBox"  >
          <img src={videoJS.poster} style={{width:854, height:479,filter: 'grayscale(100%)'}}/>
                      <div class="videoTranscodingBoxText">
            <h1>Encoding {vidID.length==16?"Audio":"Video"}</h1>
        </div>
          </div>
          
        }
        <div class="player-date-options">
          <div class="player-date">
            {(videoJS.createdAt !== undefined && videoJS.updatedAt<=videoJS.createdAt) && <h3>Created {CreatedDate}</h3>}
            {(videoJS.updatedAt !== undefined && videoJS.updatedAt>videoJS.createdAt) && <h3>Updated At {UpdateDate}</h3>}
          </div>
          <div class="player-options">
            <Favorite obj = {videoJS}/>
            <Like obj ={videoJS} />
            {/*
            <Share/>
             */}
            <More menuFunc={handleMenuClick}/>
          </div>
        </div>
         {showTranscript &&
         <div >
         <br/>
           <Box sx={{ flexGrow: 1 }}>
           <AppBar position="static">
       <Toolbar>
       <Box sx={{ flexGrow: 1 }}>
        <Typography variant="h6" component="div">
            Transcript
          </Typography>
          </Box>
          <Box edge="end" style={{marginLeft:'auto'}}sx={{ flexGrow: 0 }}>
            
             <IconButton aria-label="ZoomIn" onClick={zoomOut} style={{color:'#fff'}} className={classes.margin}>
              <ZoomOutIcon   />
            </IconButton>
            <IconButton aria-label="ZoomIn" onClick={zoomIn} style={{color:'#fff'}}className={classes.margin}>
              <ZoomInIcon  />
            </IconButton>
              <IconButton aria-label="ZoomIn" onClick={()=>setShowTranscript(false)}  style={{color:'#fff'}}  className={classes.margin}>
              <CancelIcon   />
            </IconButton>
            </Box>
      </Toolbar>
       </AppBar>
       </Box>
        <Card>
          <CardContent style={{fontSize: fontsize , maxHeight:300, overflow:'scroll'}}>
            <div dangerouslySetInnerHTML={{ __html: transcriptHTML }}></div>
          </CardContent>
        </Card>
         
          </div>
         }
        <div class="player-metadata">
          <h2 class="video-title" >
            {videoJS.title}
          </h2>
          <p class="video-description">
          <span dangerouslySetInnerHTML={{__html:desc,}} />    
          </p>
          <p>Duration:  {videoJS.duration}     </p>
        </div>
        <div style={{ borderTop: "2px solid #E20074" }}></div>
        <PlayerKeywords data={videoJS.keywords} redirect={props.history.push}/>
        
      <div
        style={{
          marginLeft: '12px',
          marginRight: '12px',
        }}>
       {console.log(videoJS.channel)}
        {videoJS.channel &&
        <div
        style={{
          marginTop: '24px',
        }}>
        
          {videoJS.channel.map(channel =>
          <ChannelTile
            id = {channel.id}
            icon = {channel.thumbnail}
            title = {channel.title}
            showInfo={true}
            description = {channel.description}
            subscribed= {channel.subscribed}
            subscribers={channel.subscribers}
            updated = {utils.formatDate(channel.createdAt)}
             playlist = {channel.playlists!=null? channel.playlists: 0}
            videos={channel.videos!=null? channel.videos: 0}
             showButton ={true}
          />
          )}
          </div>
        }
      </div>
      </div>
       <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        
        <MenuItem onClick={copyIDtoClipboard }>Copy Video ID to Clipboard</MenuItem>
        <MenuItem onClick={handleShareOpen }>Share</MenuItem>
          {userInfo.role=='Administrator' && <MenuItem onClick={handleEdit }>Edit</MenuItem> }
         { (userInfo.role=="Administrator" || userInfo.role=="Podcast-Author"||userInfo.role=="Channel-Author"||userInfo.role=="Author")&&<MenuItem onClick={handleSourceDownload }>Download Source</MenuItem>}
        {(userInfo.role !="Viewer"&& videoJS.allowTrainerDownload == true) && <MenuItem onClick={handleTrainerDownload }>Trainer Download</MenuItem> }
         <MenuItem onClick={handleInfoOpen }>Properties</MenuItem>
      </Menu>
      {havePlaylist &&
        <PlayerPlaylist style={{paddingLeft:30}}playlistID={values.playlist} index={index} refresh={()=>renderThis()} autoplay={autoplay} userRole={userInfo.role} />
      }
      <ShareVideo open={shareOpen} close={closeShare} id={thisVideo} title={videoJS.title} />
   <VideoInfo open={infoOpen} close={handleInfoClose}  id={thisVideo}router={props.history}/>
   
    </div>
   
    );
  }
  return <Paper/>;
}

Player.propTypes = {
  classes: PropTypes.object.isRequired,
};

//export default connect(null, mapDispatchToProps)( withStyles(style)(Player));
export default compose(
  withStyles(style, {
    name: 'Player',
  }),
  connect(null, mapDispatchToProps),
)(Player);
