import React, { useEffect } from 'react';
import '../style/splash.css';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import queryString from 'query-string'
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Check from '@material-ui/icons/Check';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { DropzoneArea } from 'material-ui-dropzone'
import Amplify, { Auth, API, graphqlOperation, Cache, Storage } from 'aws-amplify';
import * as utils from '../functions/util.js';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import Logo from '../images/brand/tmo-clips-logo.svg';
import ErrorGraphic from '../images/splash/error.png';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
const roles = [{
    value: 'default',
    label: 'Select a group',
  },
  {
    value: 'Consumer_Retail_Sales',
    label: 'Consumer Retail Sales',
  },
  {
    value: 'Consumer_Telesales',
    label: 'Consumer Telesales',
  },
  {
    value: 'Business_Sales',
    label: 'Business Sales',
  },
  {
    value: 'Consumer_Indirect_Sales',
    label: 'Consumer Indirect Sales',
  },
  {
    value: 'Customer_Care_Sprint',
    label: 'Customer Care Sprint',
  },
  {
    value: 'Customer_Care_Vendor',
    label: 'Customer Care Vendor',
  },
  {
    value: 'Network',
    label: 'Network',
  },
  {
    value: 'IT',
    label: 'IT',
  },
  {
    value: 'HR',
    label: 'HR',
  },
  {
    value: 'Finance',
    label: 'Finance',
  },
  {
    value: 'Other_Sprint',
    label: 'Other Sprint',
  },
  {
    value: 'Other_Non-Sprint',
    label: 'Other Non-Sprint',
  },

];

const vendor_roles = [{
    value: 'default',
    label: 'Select a line of business',
  },
  {
    value: 'Consumer Sales',
    label: 'Consumer Sales',
  },
  {
    value: 'Business Sales',
    label: 'Business Sales',
  },

  {
    value: 'Customer Care',
    label: 'Customer Care',
  },
  {
    value: 'Telesales',
    label: 'Telesales',
  },


];
const QontoConnector = withStyles({
  alternativeLabel: {

  },
  active: {
    '& $line': {
      borderColor: '#E20074',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#E20074',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
  label: {
    color: "#fff",
  },
  root: {
    color: "#fff",
  }

})(StepConnector);
const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#E20074',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#E20074',
  },
  circle: {
    color: '#ccc',
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#E20074',
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}
QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
};
const useStyles = makeStyles(theme => ({
  modal: {
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '300px,',
    marginTop: '100px'
  },
  paper: {
    backgroundColor: "transparent",
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),

  },
  root: {
    width: '100%',
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  progress:{
    height: 200,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bigAvatar: {
    margin: 10,
    width: 120,
    height: 120,
    border: "5px white solid",
    left: "40%"
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepper: {
    backgroundColor: "transparent",
    "& .MuiStepLabel-active": {
      color: "#ccc"
    },
    "& .MuiStepLabel-completed": {
      color: "#999"
    },
    "&.Mui-disabled .MuiStepLabel-label": {
      color: "#999"
    }
  },
  stepLabel: {
    color: "#fff"
  },
  textField: {
    width: '100%',
    minHeight: '100%',
  },
  textFieldInput: {
    width: '90%',
    backgroundColor: 'black',
    padding: '10px',
    border: '1px solid rgba(226, 0, 116, 0.5)',
    minHeight: 100
  },
  dropzone: {
    position: 'relative',
    width: '100%',
    minHeight: 50,
    backgroundColor: '#272727;',
    border: '1px solid #ccc',
    cursor: 'pointer',
    boxSizing: 'border-box'
  },
  dropzone: {
    position: 'relative',
    width: '100%',
    minHeight: 50,
    backgroundColor: '#272727;',
    border: '1px solid #ccc',
    cursor: 'pointer',
    boxSizing: 'border-box'
  },

}));


const mapStateToProps = state => ({
  isLogged: state.isLogged,
})

function SplashPage(props) {
  console.log(props)
  let profile
  let myOriginalRole

  const [firstTimeUser, setFirstUser] = React.useState(false)
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [ready, setReady] = React.useState(true);
  const [user, setUser] = React.useState(Cache.getItem('userInfo'));
  const [hasPhoto, setPhoto] = React.useState(false);
  const [newRole, setNewRoleFlag] = React.useState(false);
  const [photoURL, setPhotoURL] = React.useState();
  const [myprofile, setValues] = React.useState({
    bio: '',
    interests: ''
  });
  const [isVendor, setIsVendor] = React.useState(false);
  const [needToPickRole, setNeedToPickRole] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [allowNext, setAllowNext] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState();
  const [companyRole, setCompanyRole] = React.useState();

  async function saveProfile() {
    console.log(myprofile)

    let user = Cache.getItem('userInfo')
    console.log(user)

    let mut
    if (!isVendor) {
      mut = `mutation UpdateUser {
        updateUser(input:{
          id:"${user.id}"
          biography:"${myprofile.bio||''}"
          interests:"${ myprofile.interest}"
          
        })
        {
          id
          biography
          groupName
          interests
          }}`
    }

    console.log(mut)
    const updateUser = await API.graphql(
      graphqlOperation(mut, { "biography": myprofile.bio })
    );
    //Cache.setItem('userInfo', 'interests', profile.interest);
    console.log(myprofile)

  }

  function getSteps() {
    return ['Upload profile picture', 'Tell us about yourself'];
  }

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();

  const isStepOptional = step => {
    //return step === 1;
  };

  const isStepSkipped = step => {
    return skipped.has(step);
  };

  const signOut = () => {
    console.log('signOut')
    Auth.signOut({ global: true })
      .then(data => console.log(data))
      .catch(err => console.log(err));
  }

  const handleNext = () => {
    let newSkipped = skipped;

    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if (activeStep == 0) {
      //setNewRoleFlag(true)
    }
    if (activeStep == 1) {
      saveProfile()
    }
    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }
    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(prevSkipped => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped; //
    });
  };

  const handleLogout = () => {
    saveProfile() 
    signOut()
  };

  const logIn = () => {
    window.location.replace(process.env.REACT_APP_CALLBACK_URL);
  }
  const handleChange = name => event => {
    if (event.target.value != 'Not Selected') setNewRoleFlag(false)
    if (event.target.value == 'default') setNewRoleFlag(true)

    if (event.target.name == 'email') {
      setNewRoleFlag(true)
      if (utils.validateEmail(event.target.value)) {
        setNewRoleFlag(false)
      }
      else {
        setNewRoleFlag(true)
      }

    }
    console.log('name ' + event.target.name + ' event ' + event.target.value)
    const { name, value } = event.target
    setValues({ ...myprofile, [name]: value })
  };

  const updateCompanyRole = name => event => {
    if (event.target.value != 'Not Selected') {
      setCompanyRole(event.target.value)

      setAllowNext(true)
    }
    else setAllowNext(false)
  };


  const handleUpdateCompanyRole = name => event => {
    utils.updateCompanyRole(user, companyRole).then(result => {
      console.log(result.data.addOrUpdatePartners.audience)
      setValues({ ...myprofile, ['groupName']: result.data.addOrUpdatePartners.audience })
      setNeedToPickRole(false)
    })
  };



  function getStepContent(step) {
    const styles = {
      dropzone: {
        position: 'relative',
        width: '100%',
        minHeight: 50,
        backgroundColor: '#272727',
        border: '1px solid #ccc',
        cursor: 'pointer',
        boxSizing: 'border-box'
      },
    }
    const handlePosterDrop = (files) => {
      let user = Cache.getItem('userInfo')
      let finalPhoto
      let hasPhoto = false;
      console.log(user)
      if (files.length > 0) {

        const extension = files[0].name.split('.')
        const tmp = user.name
        //  const emailRoot = tmp[1].split('.')
        const filename = "profile_images/" + user.name + "." + extension[1]
        Storage.put(filename,
            files[0], {
              progressCallback(progress) {
                // setPosterCompleted((progress.loaded/progress.total)*100);
              },
            })
          .then(result => {
            utils.updateProfilePhoto(filename).then(result => {
              finalPhoto = process.env.REACT_APP_CLOUDFRONT_URL + result.data.updateUser.profilePhotoURL
              setPhoto(true)
              setPhotoURL(finalPhoto)
            })
          })
          .catch(err => console.log(err));
      }
    }
    switch (step) {
      case 0:
        return <Grid item>
           <p>Let's set your profile in two easy steps. First, click the icon below to upload your profile picture.</p>
           {!hasPhoto &&
              <DropzoneArea
                dropzoneText="Drag your profile image here or click to browse."
                acceptedFiles={['.gif','.png','.jpg']}
                showFileNames={true}
                filesLimit={1}
                maxFileSize={3000000}
                onChange={handlePosterDrop.bind(this)}
                dropzoneClass={classes.dropzone}/>
           }
           {hasPhoto &&
             <Avatar alt="" src={photoURL} className={classes.bigAvatar} />
           }
        </Grid>

      case 1:
        return <Grid>
      <p>Tell us about your interests.</p>
      <Grid container sm={12}>
      <Grid item sm={6}>
      <Typography  className={classes.font}  variant="h3" component="h3" style={{fontSize: '24px', fontWeight: '100', display: 'inline-block'}} >
                  About Me
                </Typography>
        <TextField
                    multiline
                    id="standard-name"
                    rowsMax="6"
                    name='bio'
                    onChange={handleChange()}
                    className={classes.textField}
                     placeholder="In a couple of sentences, how would you describe what you do at T-Mobile?"
                    InputProps={{
                       className: classes.textFieldInput
                    }}
                    margin="normal"
                  />
       </Grid>
       <Grid item sm={6}>
       <Typography  className={classes.font}  variant="h3" component="h3" style={{fontSize: '24px', fontWeight: '100', display: 'inline-block'}} >
                  Interests
                </Typography>
        <TextField
                  multiline
                  rowsMax="6"
                  id="standard-name"
                  name = 'interest'
                  onChange={handleChange()}
                  className={classes.textField}
                  placeholder ="Interests are keywords separated by a comma and a space.                                  "
                  InputProps={{
                    className: classes.textFieldInput
                  }}
                  margin="normal"
                />
                </Grid>
      </Grid>
      </Grid>
      default:
        return <div>{()=>saveProfile()}</div>;
    }
  }

  useEffect(() => {
    console.log(props.isLogged)
    if(props.isLogged) logIn();
    if (queryString.parse(props.location.search).error_description !== undefined) {
      console.log(queryString.parse(props.location.search).error_description)
      setError(true)
      let errorMsg
      let errorCode = queryString.parse(props.location.search).error_description.split("code:")
      console.log(errorCode[1])
      switch (errorCode[1]) {
        case '403. ':
          errorMsg = "It looks like you’re trying to login to Clips as a Non-Employee using a Sprint or T-Mobile account. Try again using the T-Mobile-Employees button.";
          break;
        default:
          errorMsg = queryString.parse(props.location.search).error_description;
      }


      setErrorMsg(errorMsg)
    }
    profile = Cache.getItem('userInfo')
    setUser(profile)
    console.log(profile)
    /*
     if(profile!=null){
        myOriginalRole = profile.groupName
        if (profile.biography == '' || profile.biography ===null ||  profile.biography ==='null') profile.biography = "Add your biography"
        if (profile.interests[0] == '' || profile.interests ===null || profile.interests[0] ==='null') profile.interests = "Add your Interest"
           setValues({ ...myprofile,
            bio: profile.biography,
            interest: profile.interests,
            myRole: profile.groupName,
            email: profile.email,
          })
       */ 




    if (profile !== null) {
      console.log(props)
      if (profile.employeeType == "E" || profile.employeeType == null) {
        
        if (profile.biography == null) {
          setFirstUser(true)
          setOpen(true)
        }
       
        console.log('is employee')
      }
      /*
      else if (user.employeeType == "N") {
        //setIsVendor(true)
        console.log('is a vendor')
        console.log(user)
        if (user.email == "" || user.email === null) {
          setFirstUser(true)
          setOpen(true)
        }
        utils.checkCompanyRole(user.company).then(result => {
          console.log(result.data.getPartners)
          if (result.data.getPartners == null) {
            setNeedToPickRole(true)
          }
          else {
            let grpCnAcc = ''
            switch (result.data.getPartners.groupsCanAccess[0]) {
              case 'Vended_Care':
                grpCnAcc = 'Customer Care';
                break;
              case 'Consumer_Indirect':
                grpCnAcc = 'Consumer Sales';
                break;
              case 'Business_Indirect':
                grpCnAcc = 'Business Sales';
                break;
              case 'Telesales':
                grpCnAcc = 'Telesales';
                break;

            }
            utils.updateCompanyRole(user, grpCnAcc)
            setValues({ ...myprofile, ['groupName']: result.data.getPartners.audience })
          }
        })
      }
      */


    }

   
  }, [props.isLogged, props.obj]);

console.log(props.isLogged)
console.log(firstTimeUser)
    if(props.isLogged == true){
    return (
      <div>
         {(!firstTimeUser && !error ) &&
      <div className={classes.progress}>
   <CircularProgress color='secondary'/>
   
   </div>
    }
   {(firstTimeUser && !error && !props.isLoggled) &&

    <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
        <div class="splash-modal-main">
           <div  class="splash-modal-header">
            <Grid sm={3}><img class="splash-clips-logo2" src={Logo} alt="Sprint Clips"/></Grid>
            </div>
          <div class="splash-modal-info">

              <h3>Welcome  {user.givenName} {user.familyName}</h3>
            
              <div>
                <div> Thank you! Click the button below to log in to Clips.</div>
                   <Button
                             variant="contained"
                              color="secondary"
                              onClick={handleLogout} className={classes.button}>
                            Log in
                          </Button>
                        </div>
             </div>
              
         
          </div>
        </Fade>
      </Modal>
} 

</div>
  
  )
    }
    else return ( 
    <div class="splash-page">
    {error &&
      <div class="splash-error">
        <div class="splash-error-msg">
          <h1>Oops!</h1>
          <p>{errorMsg}</p>
           <div class="splash-button"> <button onClick={logIn}>Continue</button></div>
        </div>
      </div>          
    }
    {(!firstTimeUser && !error && !props.isLoggled) &&
    
    <div className='main cn'>
    
<div className='col cn'>
  <div className='logo'>
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1228.621 332.24">
<defs>
  <filter id="Path_255" x="136.575" y="154.287" width="74.782" height="74.785" filterUnits="userSpaceOnUse">
    <feOffset dy="3" input="SourceAlpha"/>
    <feGaussianBlur stdDeviation="5" result="blur"/>
    <feFlood flood-opacity="0.502"/>
    <feComposite operator="in" in2="blur"/>
    <feComposite in="SourceGraphic"/>
  </filter>
  <filter id="Path_256" x="0" y="52.541" width="211.359" height="251.182" filterUnits="userSpaceOnUse">
    <feOffset dy="3" input="SourceAlpha"/>
    <feGaussianBlur stdDeviation="5" result="blur-2"/>
    <feFlood flood-opacity="0.502"/>
    <feComposite operator="in" in2="blur-2"/>
    <feComposite in="SourceGraphic"/>
  </filter>
  <filter id="Path_257" x="0" y="154.287" width="74.786" height="74.785" filterUnits="userSpaceOnUse">
    <feOffset dy="3" input="SourceAlpha"/>
    <feGaussianBlur stdDeviation="5" result="blur-3"/>
    <feFlood flood-opacity="0.502"/>
    <feComposite operator="in" in2="blur-3"/>
    <feComposite in="SourceGraphic"/>
  </filter>
  <filter id="Rectangle_788" x="271.727" y="13.258" width="42.211" height="318.982" filterUnits="userSpaceOnUse">
    <feOffset dy="3" input="SourceAlpha"/>
    <feGaussianBlur stdDeviation="5" result="blur-4"/>
    <feFlood flood-opacity="0.502"/>
    <feComposite operator="in" in2="blur-4"/>
    <feComposite in="SourceGraphic"/>
  </filter>
  <filter id="Clips" x="613.621" y="55.39" width="615" height="269" filterUnits="userSpaceOnUse">
    <feOffset dy="3" input="SourceAlpha"/>
    <feGaussianBlur stdDeviation="5" result="blur-5"/>
    <feFlood flood-opacity="0.502"/>
    <feComposite operator="in" in2="blur-5"/>
    <feComposite in="SourceGraphic"/>
  </filter>
  <filter id="Path_247" x="447.224" y="112.132" width="98.873" height="134.595" filterUnits="userSpaceOnUse">
    <feOffset dy="3" input="SourceAlpha"/>
    <feGaussianBlur stdDeviation="5" result="blur-6"/>
    <feFlood flood-opacity="0.502"/>
    <feComposite operator="in" in2="blur-6"/>
    <feComposite in="SourceGraphic"/>
  </filter>
  <filter id="ic_slow_motion_video_24px" x="306.63" y="0" width="266.873" height="266.873" filterUnits="userSpaceOnUse">
    <feOffset dy="3" input="SourceAlpha"/>
    <feGaussianBlur stdDeviation="5" result="blur-7"/>
    <feFlood flood-opacity="0.502"/>
    <feComposite operator="in" in2="blur-7"/>
    <feComposite in="SourceGraphic"/>
  </filter>
  <filter id="ic_slow_motion_video_24px-2" x="530.449" y="90.316" width="103.566" height="103.566" filterUnits="userSpaceOnUse">
    <feOffset dy="3" input="SourceAlpha"/>
    <feGaussianBlur stdDeviation="5" result="blur-8"/>
    <feFlood flood-opacity="0.502"/>
    <feComposite operator="in" in2="blur-8"/>
    <feComposite in="SourceGraphic"/>
  </filter>
  <filter id="ic_slow_motion_video_24px-3" x="530.449" y="163.718" width="103.566" height="103.566" filterUnits="userSpaceOnUse">
    <feOffset dy="3" input="SourceAlpha"/>
    <feGaussianBlur stdDeviation="5" result="blur-9"/>
    <feFlood flood-opacity="0.502"/>
    <feComposite operator="in" in2="blur-9"/>
    <feComposite in="SourceGraphic"/>
  </filter>
  <filter id="ic_slow_motion_video_24px-4" x="470.348" y="223.819" width="103.566" height="103.566" filterUnits="userSpaceOnUse">
    <feOffset dy="3" input="SourceAlpha"/>
    <feGaussianBlur stdDeviation="5" result="blur-10"/>
    <feFlood flood-opacity="0.502"/>
    <feComposite operator="in" in2="blur-10"/>
    <feComposite in="SourceGraphic"/>
  </filter>
  <filter id="ic_slow_motion_video_24px-5" x="396.946" y="223.819" width="103.566" height="103.566" filterUnits="userSpaceOnUse">
    <feOffset dy="3" input="SourceAlpha"/>
    <feGaussianBlur stdDeviation="5" result="blur-11"/>
    <feFlood flood-opacity="0.502"/>
    <feComposite operator="in" in2="blur-11"/>
    <feComposite in="SourceGraphic"/>
  </filter>
</defs>
<g id="Group_1545" data-name="Group 1545" transform="translate(0 1)">
  <g id="Group_1238" data-name="Group 1238" transform="translate(15 63.541)">
    <g id="Group_1226" data-name="Group 1226" transform="translate(0)">
      <g transform="matrix(1, 0, 0, 1, -15, -64.54)" filter="url(#Path_255)">
        <path id="Path_255-2" data-name="Path 255" d="M145.82,154.047v44.785H190.6V154.047H145.82Z" transform="translate(5.76 12.24)" fill="#fff"/>
      </g>
      <g transform="matrix(1, 0, 0, 1, -15, -64.54)" filter="url(#Path_256)">
        <path id="Path_256-2" data-name="Path 256" d="M126.164,137.757,123.953,215.8l11.742,2.036q3.27-34.5,18.1-51.2c10.4-11.648,24.727-17.732,43.146-18.256V311.633c0,14.266-2,23.509-6.158,27.794q-5.3,5.462-17.95,6.708-3.738.3-12.791.306v12.5l109.183-.006V346.441q-9.05,0-12.8-.306-12.638-1.246-17.95-6.708c-4.16-4.285-6.177-13.528-6.177-27.794V148.381c18.419.525,32.765,6.608,43.165,18.256q14.831,16.7,18.113,51.2l11.736-2.036-2.217-78.041Z" transform="translate(-108.95 -73.22)" fill="#fff"/>
      </g>
      <g transform="matrix(1, 0, 0, 1, -15, -64.54)" filter="url(#Path_257)">
        <path id="Path_257-2" data-name="Path 257" d="M168.739,154.047H123.953v44.785h44.786Z" transform="translate(-108.95 12.24)" fill="#fff"/>
      </g>
    </g>
  </g>
  <g transform="matrix(1, 0, 0, 1, 0, -1)" filter="url(#Rectangle_788)">
    <rect id="Rectangle_788-2" data-name="Rectangle 788" width="12.211" height="288.982" transform="translate(286.73 25.26)" fill="#fff"/>
  </g>
  <g transform="matrix(1, 0, 0, 1, 0, -1)" filter="url(#Clips)">
    <text id="Clips-2" data-name="Clips" transform="translate(628.62 251.39)" fill="#fff" font-size="239" font-family="Helvetica-Bold, Helvetica" font-weight="700"><tspan x="0" y="0">Clips</tspan></text>
  </g>
  <g id="Group_1312" data-name="Group 1312" transform="translate(321.63 11)">
    <g transform="matrix(1, 0, 0, 1, -321.63, -12)" filter="url(#Path_247)">
      <path id="Path_247-2" data-name="Path 247" d="M-227.016,20V124.595l68.873-52.287Z" transform="translate(689.24 104.13)" fill="#fff"/>
    </g>
    <g id="Group_1235" data-name="Group 1235" transform="translate(0 0)">
      <g transform="matrix(1, 0, 0, 1, -321.63, -12)" filter="url(#ic_slow_motion_video_24px)">
        <path id="ic_slow_motion_video_24px-6" data-name="ic_slow_motion_video_24px" d="M103.922,115.534A116.288,116.288,0,0,1,0,231.067V207.612a93.07,93.07,0,0,0,80.7-92.079A93.07,93.07,0,0,0,0,23.455V0A116.288,116.288,0,0,1,103.922,115.534Z" transform="translate(395.11 248.87) rotate(-135)" fill="#fff"/>
      </g>
      <g transform="matrix(1, 0, 0, 1, -321.63, -12)" filter="url(#ic_slow_motion_video_24px-2)">
        <path id="ic_slow_motion_video_24px-7" data-name="ic_slow_motion_video_24px" d="M0,16.6A115.9,115.9,0,0,0,61.773,42.266V18.811A92.208,92.208,0,0,1,16.488,0L0,16.6Z" transform="translate(589.13 175.88) rotate(-135)" fill="rgba(255,255,255,0.85)"/>
      </g>
      <g transform="matrix(1, 0, 0, 1, -321.63, -12)" filter="url(#ic_slow_motion_video_24px-3)">
        <path id="ic_slow_motion_video_24px-8" data-name="ic_slow_motion_video_24px" d="M23.455,0H0A115.52,115.52,0,0,0,25.661,61.773l16.6-16.6A91.358,91.358,0,0,1,23.455,0Z" transform="translate(575.33 249.28) rotate(-135)" fill="rgba(255,255,255,0.65)"/>
      </g>
      <g transform="matrix(1, 0, 0, 1, -321.63, -12)" filter="url(#ic_slow_motion_video_24px-4)">
        <path id="ic_slow_motion_video_24px-9" data-name="ic_slow_motion_video_24px" d="M42.266,16.488,25.661,0A115.52,115.52,0,0,0,0,61.773H23.455a92.208,92.208,0,0,1,18.81-45.285Z" transform="translate(515.23 309.38) rotate(-135)" fill="rgba(255,255,255,0.45)"/>
      </g>
      <g transform="matrix(1, 0, 0, 1, -321.63, -12)" filter="url(#ic_slow_motion_video_24px-5)">
        <path id="ic_slow_motion_video_24px-10" data-name="ic_slow_motion_video_24px" d="M61.773,23.455V0A115.52,115.52,0,0,0,0,25.661l16.488,16.6A92.208,92.208,0,0,1,61.773,23.455Z" transform="translate(455.63 309.38) rotate(-135)" fill="rgba(255,255,255,0.25)"/>
      </g>
    </g>
  </g>
</g>
</svg>
  </div>
  <div className='overlay'>
  <div className='icons'>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79.169 83.568">
<path id="Path_271" data-name="Path 271" d="M42.585,1A39.42,39.42,0,0,0,3,40.585V71.373A13.2,13.2,0,0,0,16.195,84.568H29.39V49.381H11.8v-8.8a30.788,30.788,0,0,1,61.576,0v8.8H55.78V84.568H68.975A13.2,13.2,0,0,0,82.169,71.373V40.585A39.5,39.5,0,0,0,42.585,1Z" transform="translate(-3 -1)" fill="#fff"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 126.541 84.361">
<path id="Path_273" data-name="Path 273" d="M87.361,20.06V76.3H17.06V20.06h70.3M94.391,6H10.03A7.03,7.03,0,0,0,3,13.03v70.3a7.03,7.03,0,0,0,7.03,7.03H94.391a7.03,7.03,0,0,0,7.03-7.03V58.725l28.12,28.12V9.515l-28.12,28.12V13.03A7.03,7.03,0,0,0,94.391,6Z" transform="translate(-3 -6)" fill="#fff"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 97.671 84.361">
<path id="Path_274" data-name="Path 274" d="M54.587,3l9.186,1.875L46.244,87.361l-9.186-1.875L54.587,3m31.4,42.18L69.162,28.355V15.092L99.251,45.18,69.162,75.222V61.959L85.987,45.18m-84.407,0L31.669,15.092V28.355L14.843,45.18,31.669,61.959V75.222Z" transform="translate(-1.58 -3)" fill="#fff"/>
</svg>
<svg className='podcastIcon' xmlns="http://www.w3.org/2000/svg"xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 101.568 101.568">
<defs>
  <filter id="Path_262" x="0" y="71.033" filterUnits="userSpaceOnUse">
    <feOffset dy="3" input="SourceAlpha"/>
    <feGaussianBlur stdDeviation="3" result="blur"/>
    <feFlood flood-opacity="0.161"/>
    <feComposite operator="in" in2="blur"/>
    <feComposite in="SourceGraphic"/>
  </filter>
  <filter id="Path_263" x="0" y="54.319" filterUnits="userSpaceOnUse">
    <feOffset dy="3" input="SourceAlpha"/>
    <feGaussianBlur stdDeviation="3" result="blur-2"/>
    <feFlood flood-opacity="0.161"/>
    <feComposite operator="in" in2="blur-2"/>
    <feComposite in="SourceGraphic"/>
  </filter>
  <filter id="Path_264" x="0" y="37.605" filterUnits="userSpaceOnUse">
    <feOffset dy="3" input="SourceAlpha"/>
    <feGaussianBlur stdDeviation="3" result="blur-3"/>
    <feFlood flood-opacity="0.161"/>
    <feComposite operator="in" in2="blur-3"/>
    <feComposite in="SourceGraphic"/>
  </filter>
  <filter id="Path_266" x="25.07" y="0" filterUnits="userSpaceOnUse">
    <feOffset dy="3" input="SourceAlpha"/>
    <feGaussianBlur stdDeviation="3" result="blur-4"/>
    <feFlood flood-opacity="0.161"/>
    <feComposite operator="in" in2="blur-4"/>
    <feComposite in="SourceGraphic"/>
  </filter>
  <filter id="Path_268" x="42.62" y="41.366" filterUnits="userSpaceOnUse">
    <feOffset dy="3" input="SourceAlpha"/>
    <feGaussianBlur stdDeviation="3" result="blur-5"/>
    <feFlood flood-opacity="0.161"/>
    <feComposite operator="in" in2="blur-5"/>
    <feComposite in="SourceGraphic"/>
  </filter>
</defs>
<g id="Component_1_1" data-name="Component 1 – 1" transform="translate(9 6)">
  <g id="Group_1328" data-name="Group 1328">
    <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#Path_262)">
      <path id="Path_262-2" data-name="Path 262" d="M2,114.818v12.535H14.535A12.311,12.311,0,0,0,2,114.818" transform="translate(7 -37.79)" fill="#fff" opacity="0.25"/>
    </g>
    <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#Path_263)">
      <path id="Path_263-2" data-name="Path 263" d="M2,88.272v8.357a20.689,20.689,0,0,1,20.892,20.892h8.357A29.069,29.069,0,0,0,2,88.272" transform="translate(7 -27.95)" fill="#fff" opacity="0.45"/>
    </g>
    <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#Path_264)">
      <path id="Path_264-2" data-name="Path 264" d="M2,61.727v8.357a37.449,37.449,0,0,1,37.605,37.605h8.357A45.83,45.83,0,0,0,2,61.727" transform="translate(7 -18.12)" fill="#fff" opacity="0.65"/>
    </g>
    <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#Path_266)">
      <path id="Path_266-2" data-name="Path 266" d="M91.959,2H50.175a8.381,8.381,0,0,0-8.357,8.357v27.16A54.692,54.692,0,0,1,53.1,45.455,21.63,21.63,0,0,1,71.067,35.427a20.892,20.892,0,0,1,0,41.784h-.836a56.628,56.628,0,0,1,.836,8.357H91.959a8.381,8.381,0,0,0,8.357-8.357V10.357A8.381,8.381,0,0,0,91.959,2M71.067,27.07a8.357,8.357,0,1,1,8.357-8.357,8.381,8.381,0,0,1-8.357,8.357" transform="translate(-7.75 4)" fill="#fff"/>
    </g>
    <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#Path_268)">
      <path id="Path_268-2" data-name="Path 268" d="M81.39,93.188a7.111,7.111,0,0,1-2.925-.418,57.967,57.967,0,0,0-8.775-16.714A12.735,12.735,0,0,1,81.39,67.7,12.311,12.311,0,0,1,93.925,80.235,12.666,12.666,0,0,1,81.39,93.188" transform="translate(-18.07 -20.33)" fill="#fff" opacity="0.85"/>
    </g>
  </g>
</g>
</svg>

  </div>
    <p>T-Mobile’s on-demand media platform for enterprise learning and communications.</p>
    <p>Upload, manage, and embed internal-use content that engages your audience.</p>
    <button  onClick={logIn}>
      Continue
    </button>
  </div>
  <div className='cw'>
    © {new Date().getFullYear()} T-Mobile
  </div>
</div>
</div>

}
{(!firstTimeUser && !error && props.isLoggled) &&
   <div className={classes.progress}>
   <CircularProgress color='secondary'/>
   
   </div>

}
{(firstTimeUser && !error && !props.isLoggled) &&
   <div className={classes.progress}>
   <CircularProgress color='secondary'/>
   
   </div>
} </div>)

}


//export default connect(mapStateToProps, null)(SplashPage);
export default withRouter(SplashPage);
