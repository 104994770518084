import React from 'react';
import ReactDOM from "react-dom";
import * as utils from '../../functions/util.js';
import './Channel.css';
import SortDateAlpha from '../widgets/SortDateAlpha';
import PlaylistItem from '../Playlist/PlaylistItem.js';
import Wrap from '../../functions/Wrap.js';
//import playlist from '../../sampleData/playlist.js';

function ChannelPlaylists(props){
  console.log(props)
  return (
    <Wrap>
      <div class="d-flex flex-row align-items-center mt-1 mb-1">
        <svg class="playlist-title-icon-playlist" viewBox="3 1 21 20"><use href="images/icons/icons.svg#playlist" /></svg>
        <h1 class="SprintSansWeb-Light video-playlist-header">Playlists</h1>
       {/*  <SortDateAlpha style={{margin: '0'}}/>*/}
      </div>
      <div style={{
        borderBottom: '2px solid #E20074',
        width: '100%',
        paddingBottom: '16px',
      }}>
        {props.playlist.map(item =>

          <Wrap>
           {console.log(item)}
            <PlaylistItem
              id={item.id}
               firstVideo={item.videoArray!=null?item.videoArray[0]:''}
              thumbnail={item.audios.items.lenght>0?item.audios.items[0].thumbnail:''||item.videos.items.length>0?item.videos.items[0].thumbnail:''}
              title={item.title}
              description={item.description}
              date={utils.formatDate(item.createdAt)}
              videos ={item.videos.items}
              haveMenu={false}
              videoArray={item.videoArray}
            />
            <div class="playlist-divider"></div>
          </Wrap>
        )}
      </div>
    </Wrap>
  )
}

export default ChannelPlaylists;