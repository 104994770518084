import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { Typography, Grid, GridList, GridListTile, GridListTileBar, IconButton } from '@material-ui/core';
//import VideoThumb from './VideoThumb'
import VideoThumb from './videoCard/VideoCard.js';
//import Age from '../functions/Age.js';
import Card from './videoCard/Card.js';
import VideoCardRow from './videoCard/VideoCardRow.js';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    marginTop: '5px',
    marginLeft: '8px;',
    flexDirecton: 'column',
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
    flexDirecton: 'rows',
  },
  yellowDivider: {
    backgroundColor: '#E20074',
    height: '1px',
    display: 'flex',
    minWidth: '100%'
  },
  title: {
    color: '#fff'
  },
  gridListTile: {
    maxWidth: '220px',
  }

});

function HorizontalPlaylist(props) {
  const { classes } = props;
  const isCondensed = (props.type == "condensed");
  let vidPerCol = 4;
  if (isCondensed) vidPerCol = 2;


  return (
    <VideoCardRow  title={props.title} func = {props.func}/>
    /*
    <Grid container direction='column' className={classes.root}>
      <Grid item>
        <Typography gutterBottom className={classes.title} variant="h5" >
          {props.title}
        </Typography>
      </Grid>
      <Grid item>
        <GridList 
          className={classes.gridList} 
          cellHeight={'auto'} 
          cols={vidPerCol} 
          spacing='4'>
            {playlist.map(p =>
              <GridListTile 
                className = {classes.gridListTile}
                key = {p.id}>
                  <VideoThumb 
                    image = {p.poster_image_loc}
                    title = {p.video_title}
                    isSmall = {!isCondensed}
                    views = {p.views}
                    age = {Age(p.createdAt)}
                    duration = {p.duration}
                    channel = {p.channel.channel_title}
                    id = {p.id}
                  />
              </GridListTile>
              )}
        </GridList>
      </Grid>
  </Grid>
  */
  );
}

HorizontalPlaylist.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HorizontalPlaylist);
