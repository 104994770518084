import React, { useEffect,Fragment,useState } from "react";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import * as utils from '../../functions/util.js';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withRouter } from "react-router";
import { withStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import '../../style/VideoCard.css';
import defaultImage from '../../images/icons/deleted.jpg'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import SharePlaylist from '../PlaylistShare.js';
const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));
const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);
const Favorite = () =>
  <svg
    class="history-svg-icon"
    viewBox="0 0 24 24">
      <path d="M12,15.39L8.24,17.66L9.23,13.38L5.91,10.5L10.29,10.13L12,6.09L13.71,10.13L18.09,10.5L14.77,13.38L15.76,17.66M22,9.24L14.81,8.63L12,2L9.19,8.63L2,9.24L7.45,13.97L5.82,21L12,17.27L18.18,21L16.54,13.97L22,9.24Z" />
  </svg>;

const Like = () =>
  <svg
    class="history-svg-icon"
    viewBox="0 0 24 24">
      <path d="M5,9V21H1V9H5M9,21A2,2 0 0,1 7,19V9C7,8.45 7.22,7.95 7.59,7.59L14.17,1L15.23,2.06C15.5,2.33 15.67,2.7 15.67,3.11L15.64,3.43L14.69,8H21C22.11,8 23,8.9 23,10V12C23,12.26 22.95,12.5 22.86,12.73L19.84,19.78C19.54,20.5 18.83,21 18,21H9M9,19H18.03L21,12V10H12.21L13.34,4.68L9,9.03V19Z" />
  </svg>;



const PlaylistItem = (props) => {
  console.log(props)
  let menuClick = false;
  const [duration, setDuration] = useState();
  const [thumbnail, setThumbnail] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [shareOpen, setShareOpen] = React.useState(false);

  const handleClick = e => {
    console.log(Boolean(anchorEl))
    e.stopPropagation()
    //if (!e) var e = window.event;
    //e.cancelBubble = true;
    //if (e.stopPropagation) e.stopPropagation();
    setAnchorEl(e.currentTarget);
    menuClick=true
  };
  const handleEdit =()=>{
   if(!menuClick) props.history.push(`/editPlaylist?p=${props.id}`);
  }
  const handleWatch =e=>{
   // console.log('handle watch')
  e.preventDefault()
    if(!menuClick) props.history.push(`/Watch?p=${props.id}&index=1`);
    
  }
  const handleShareOpen = () => {
    setAnchorEl(null);
    setShareOpen(true)
  };
   const closeShare = (id) => {
      setShareOpen(false)
    }
   const deletePlaylist =()=>{
      setOpen(true);
     handleCloseMenu()

  }
  const handleDelete =()=>{
      props.removeMe(props.id)
       setOpen(false);
    handleCloseMenu()
  }
  const handleDialogClose = () => {
    setOpen(false);
    handleCloseMenu()
  };
    const handleClose = () => {
    setAnchorEl(null);
    setOpen(false)
  };
   const handleCloseMenu=()=>{
     setAnchorEl(null);
  }
  const videoMenu = ()=> {
   return(

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
      

       {props.videoMenu.map(menu =>
        <StyledMenuItem onClick={()=>{
            menu.func(props.id);
            setAnchorEl(null);
        }}>
          <ListItemText primary={menu.name} />
        </StyledMenuItem>
        )}
        
         <StyledMenuItem onClick={()=>{handleShareOpen()}}>
            <ListItemText primary ="Share"/>
          </StyledMenuItem>
     


      </StyledMenu>

     )
 }

 useEffect(() => {
   //console.log('refresh')
   console.log(props)
   const server = process.env.REACT_APP_CLOUDFRONT_URL.split('/')
   let fitThumbURL
  if(props.firstVideo!==undefined){
  if(props.firstVideo.length<14){
    utils.searchVideoByID(props.firstVideo).then(function(result) {
      console.log(result)
      if(result.data.searchVideos.items.length>0){
          
          const extension = result.data.searchVideos.items[0].thumbnail.split('.')
          fitThumbURL = `https://${server[2]}/fit-in/220x124/public/vco/${result.data.searchVideos.items[0].id}/poster.${extension[extension.length-1]}`
          setThumbnail(fitThumbURL)
        
      }
    })
  }
  else{
    utils.searchAudioByID(props.firstVideo).then(function(result) {
      
      let audio = JSON.parse(result.data.searchForAudio)
      console.log(audio)
     
         
          const extension = audio.thumbnail.split('.')
          fitThumbURL = `https://${server[2]}/fit-in/220x124/public/vco/${audio.id}/poster.${extension[extension.length-1]}`
          setThumbnail(fitThumbURL)
        
      
    })

  }

}
   
   console.log(props.videos)
   let durationArr = props.videos.map(a => utils.hmsToSecondsOnly(a.videoDuration));
   //console.log(durationArr)
   const arrSum = durationArr.reduce((a,b) => a + b, 0)
    //console.log(new Date(arrSum * 1000).toISOString().substr(11, 8))
   if(!isNaN(arrSum)){
      setDuration(utils.removeUnneccesaryZero(new Date(arrSum * 1000).toISOString().substr(11, 8)))
   }

 }, [props]);
    return (
        <div id={props.id} class="playlist-item" >

          <div class="playlist-thumbnail" onClick={handleWatch}>
           <div > 
             <LazyLoadImage src={thumbnail} alt={props.title} onClick={handleWatch} style={{maxHeight:123}}placeholderSrc={defaultImage}/>
          {/* <img src={thumbnail} alt={props.title}  onClick={handleWatch}/>*/}
           </div>
           {/*
            <div class="playlist-overlay">
              <div class="playlist-duration">
                <svg class="playlist-duration-icon" viewBox="0 1 24 24">
                  <path d="M12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22C6.47,22 2,17.5 2,12A10,10 0 0,1 12,2M12.5,7V12.25L17,14.92L16.25,16.15L11,13V7H12.5Z" />
                </svg>
                <span class="playlist-duration-time">{duration}</span>
              </div>
              <div class="playlist-videos"></div>
              <div class="playlist-icon">
                <svg viewBox="-2 0 24 24">
                  <path d="M19,9H2V11H19V9M19,5H2V7H19V5M2,15H15V13H2V15M17,13V19L22,16L17,13Z" />
                </svg>
                 {props.videos.length} Videos
              </div>
            </div>
           */}
          </div>
          <div class="playlist-metadata">
            <div class="playlist-header">
              <svg viewBox="-2 0 24 24">
                <path d="M19,9H2V11H19V9M19,5H2V7H19V5M2,15H15V13H2V15M17,13V19L22,16L17,13Z" />
              </svg>
              <span class="playlist-title">{props.title}</span>
              {/*
              <div class="playlist-share">
                <svg class="share-icon" viewBox="0 2 24 24">
                  <path d="M18,16.08C17.24,16.08 16.56,16.38 16.04,16.85L8.91,12.7C8.96,12.47 9,12.24 9,12C9,11.76 8.96,11.53 8.91,11.3L15.96,7.19C16.5,7.69 17.21,8 18,8A3,3 0 0,0 21,5A3,3 0 0,0 18,2A3,3 0 0,0 15,5C15,5.24 15.04,5.47 15.09,5.7L8.04,9.81C7.5,9.31 6.79,9 6,9A3,3 0 0,0 3,12A3,3 0 0,0 6,15C6.79,15 7.5,14.69 8.04,14.19L15.16,18.34C15.11,18.55 15.08,18.77 15.08,19C15.08,20.61 16.39,21.91 18,21.91C19.61,21.91 20.92,20.61 20.92,19A2.92,2.92 0 0,0 18,16.08Z" />
                </svg>
                <span>Share</span>
              </div>
              */}
               {props.haveMenu &&
                <div class='more-menu' onClick={handleClick}>
               <MoreVertIcon fontSize={'small'} style={{width:25,top:-3,float:'right',cursor:'pointer'}} />
                </div>


              }


            <div class="playlist-details">
              {(props.videoArray!=null?props.videoArray.length:0)} Media • Total Running Time {duration} • Created {props.date}
            </div>
            <div class="playlist-description">
              <p>{decodeURIComponent(props.description)}</p>
            </div>



             </div>

      </div>

      {props.videoMenu!==undefined &&
       <div>
        {videoMenu()}
        </div>
      }
        <SharePlaylist open={shareOpen} close={closeShare} id={props.id}  />
      </div>





    )
}

export default withRouter(PlaylistItem);

 {/*
              <div>
              <div class="playlist-edit" onClick={handleEdit}>
                <svg class="edit-icon" viewBox="0 2 24 24">
                  <path d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" />
                </svg>
                <span>Edit</span>

              </div>

              <div class="playlist-edit" onClick={deletePlaylist}>
               <DeleteForeverIcon/>
                <span>Delete</span>

              </div>

            </div>
            */}