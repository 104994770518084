import React ,{useEffect,useState}from 'react';
import VideojsComponent from "./VideojsComponent";
//import videoJsOptions from "./videoJsOptions.js";

function VideoPlayer(props) {
    console.log(props)
   const videoJsOptions = {
      autoplay: props.autoplay,
      toggleFunc: props.toggleFunc
    }

   useEffect(() => {
     console.log(props)
 },[]);

    //ReactDOM.render(<VideoPlayer {...videoJsOptions} />, rootElement);
    //return <VideojsComponent {...videoJsOptions} />;
    return (
        
        <VideojsComponent
        
          {...props.vid.videoJS}
          { ...videoJsOptions } 
          {...props.toggleFunc}
          
          //vid={this.state.videoJS.id}
         
         //width={this.state.width}
        />
    );
  }

export default VideoPlayer;
