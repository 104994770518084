import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
//import ClipIcon from '/images/icons/clips_icon.svg';
import onebyoneIcon from '../images/icons/1x1.png';
import sixteenbynineIcon from '../images/icons/16x9.png';
import twentyfourynineIcon from '../images/icons/24x9.png';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core//ButtonGroup';
import Box from '@material-ui/core//Box';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import ShareIcon from '@material-ui/icons/Share';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CodeIcon from '@material-ui/icons/Code';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import LinkIcon from '@material-ui/icons/Link';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { styled } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import * as utils from '../functions/util.js';
import { requirePropFactory } from '@material-ui/core';
var isIE = /*@cc_on!@*/ false || !!document.documentMode;
// Edge 20+
var isEdge = !isIE && !!window.StyleMedia;
// Chrome 1 - 71
var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
const isFirefox = typeof InstallTrigger !== 'undefined';
const server = process.env.REACT_APP_CLOUDFRONT_URL.split('/')
const serverType = server[2].split('.')

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    maxWidth:480,
  },
}));

const Item = styled(Paper)(({ theme }) => ({
 
  padding: theme.spacing(1),
  textAlign: 'center',
  backgroundColor:'#000',
  color:'#fff',
  //color: theme.palette.text.secondary,
}));

function SimpleTabs(props) {
  console.log(props)
  let id = props.id
  let title = props.title
  let audience = props.audience
  const classes = useStyles();
  const [aspectvalue, setAspectValue] = React.useState(0);
  const [value, setValue] = React.useState(0);
  const [bGA, setBGA] = React.useState(false);
  const [bAllowSkip, setBAllowSkip] = React.useState(true);
  const [allowSkip, setAllowSkip] = React.useState(false);
  const [GA, setGA] = React.useState('');
  const [link, setLink] = React.useState();
  const [linkType, setLinkType] = React.useState(false);
  const[width, setWidth] = React.useState(720);
  const[height, setHeight] = React.useState(405);
  const[aspectType, setAspectType] = React.useState('16x9');
  const[showTranscript, setShowTranscript] = React.useState(false);
  const[transcriptDefault, setTranscriptDefault] = React.useState(false);
  const[showControl, setShowControl] = React.useState(true);
  const[showCaption, setShowCaption] = React.useState(true);
  const[required, setRequired] = React.useState(false);
   const[isVerticalVideo, setIsVerticalVideo] = React.useState(false);
  const[showTranscriptDefault, setShowTranscriptDefault] = React.useState(false);

 utils.searchVideoByID2(id).then(function(result) {
  const res =  JSON.parse(result.data.searchForAVideo)
  console.log(res)
  let aspectRatio = res.width/res.height
  if(aspectRatio<1) {
    setWidth(495)
    setHeight(720)
    setIsVerticalVideo(true)
  }
  if(res.hasOwnProperty('transcripts')) setShowTranscript(true)
});
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handlePlayerChange = (event, newValue) => {
    setLinkType(newValue)
    if (newValue == 'lms') setBAllowSkip(false)
    else setBAllowSkip(true)
  };
   const handleAspectChange = (event, newValue) => {
    setAspectType(newValue)
    console.log(newValue)
    switch (newValue) {
      case '16x9':
        setWidth(720)
        setHeight(405)
        setShowTranscriptDefault(false)
        break;
      case '24x9':
        setWidth(1080)
        setHeight(405)
         setShowTranscriptDefault(true)
        break;
      case '1x1':
       setWidth(720)
        setHeight(720)
        setShowTranscriptDefault(true)
        break;
    
    }
    
  };

  const handleAlowSkip = (event, newValue) => {
    setAllowSkip(event.target.checked)
    //console.log(allowSkip)
  };
  const handleGAChange = (event, newValue) => {
    setBGA(event.target.checked)
  };
   const handleTransriptChange = (event, newValue) => {
    setTranscriptDefault(event.target.checked)
  };

  const handelSetChangeGA = (event, newValue) => {
    setGA(event.target.value)
  }
  const handleToggleCaption = (event, newValue) => {
   
   setShowCaption(!showCaption)
  };

  const handleToggleControl = (event, newValue) => {
    setShowControl(!showControl)
   };

   const handleToggleRequired = (event, newValue) => {
    setRequired(!required)
   };

   const openInfo = (url) => {
    window.open(url,"_blank")
   };

  const generateCode = () => {
    let link
    let GAcode = ''
    let skip = ''
    let control =''
    let caption =''
    let require = ''
    if (allowSkip) skip = "&required"
    if(showControl) control ="&controls"
    if(showCaption) caption ="&cc"
    if(required) require ="&required"
    console.log(control)
    if (bGA && GA != '') GAcode = `&ga=${GA}`
    console.log(skip)
    switch (linkType) {
      case "shareppoint_SA":
        link = `https://tmobileusa.sharepoint.com/sites/assets/SiteAssets/video/index.aspx?playlist=${id}&mobile=0${GAcode}${control}${caption}${require}`
        break;
      case "media_tmbobile":
        link = `https://media.t-mobile.com/launch/?playlist=${id}${GAcode}${control}${caption}${require}`
        break;
      case "lms_SA":
        link = `https://lms.sprint.com/main/static/vcmsPlayer/?playlist=${id}${GAcode}${control}${caption}${require}`
        break;
      case "star_SA":
        link = `https://clips.t-mobile.com/main/static/vcmsPlayer/?playlist=${id}${GAcode}${control}${caption}${require}`
        break;
      case "clips_SA":
        link = `https://clips.t-mobile.com/public/playlist=${id}${GAcode}${control}${caption}${require}`
        break;
      case "clips_SA":
        link = `https://clips.sprint.com/public/?playlist=${id}${GAcode}${control}${caption}${require}`
        break;
        case "magentaU_SA":
          link = `https://t-mobile.mlearning.com/static/clips/clips.html?playlist=${id}${GAcode}${control}${caption}${require}`
          break;
        
      case "sharepoint":
        link = `<style> .clips-iframe-container {padding-top: 56%;width: 100%;}.clips-iframe-container iframe { position: absolute;top: 0;left: 0;width: 100%;height:100%;} </style><div class="clips-iframe-container"><iframe id="${id}"  allowfullscreen src="/sites/assets/SiteAssets/video/index.aspx?playlist=${id}${GAcode}${control}${caption}${require}&mobile=0${transcriptDefault?'&transcript=show':''}" style="border: none; overflow: hidden; margin: 0; background: transparent;" width="${width}" height="${height}" ></iframe></div>`
        break;
      case "lms":
        link = `<iframe id="${id}" src="/main/static/vcmsPlayer/?playlist=${id}${GAcode}${skip}${control}${caption}${require}${transcriptDefault?'&transcript=show':''}" style="border: none; overflow: hidden; margin: 0; background: transparent;" width="${width}" height="${height}" allowfullscreen></iframe>`
        break;
      case "c2":
        link = `<div class="video-container externalVideo"><div class="externalPlaceHolder" style="height: 100%;" title="${title}" data-video-url="https://media.t-mobile.com/launch?playlist=${id}${control}${caption}${require}${transcriptDefault?'&transcript=show':''}"><p style="display: block;"><input class="url" style="width: 0px; padding: 0px;" type="text" >&nbsp;</p></div></div>`
        break;
   
      case 'lessonly':
        link = `<iframe id="${id}"  title="${title}" src="https://media.t-mobile.com/launch?playlist=${id}${control}${caption}${require}${transcriptDefault?'&transcript=show':''}" style="border: none; overflow: hidden; margin: 0; background: transparent;" width="${width}" height="${height}" allowfullscreen></iframe>`
        break;
      case 'clips':
        link = `<iframe  id="${id}"  title="${title}" src="https://clips.sprint.com/public/?playlist=${id}${control}${caption}${require}${transcriptDefault?'&transcript=show':''}" style="border: none; overflow: hidden; margin: 0; background: transparent;"  width="${width}"  height="${height}"  allowfullscreen ></iframe>`
        break;
        
        
         case 'magentaU':
            link = `<iframe id="${id}"   src="https://t-mobile.mlearning.com/static/clips/clips.html?playlist=${id}${control}${caption}${require}" style="border: none; overflow: hidden; margin: 0; background: transparent;" width="720" height="405"  allowfullscreen></iframe>`
          break;
                  
        
      default:
        // code block
    }

    setLink(link)

  }

  useEffect(() => {
    generateCode()
  }, [linkType, GA, bGA, allowSkip,height,width,transcriptDefault,required,showCaption,showControl]);

  const copyVideoLink = () => {
    navigator.clipboard.writeText(link)
    if (isChrome || isFirefox) navigator.clipboard.writeText(link)
    else if (isIE) {
      navigator.clipboard.writeText(link)

      window.clipboardData.setData('Text', link);
      document.execCommand('copy');
    }

  }

  const copyLink = () => {
    let thisServer = serverType[1] == 'clips' ? '' : `${serverType[1]}.`
    let link = `https://${thisServer}clips.t-mobile.com/#/Watch?p=${id}`
    navigator.clipboard.writeText(link)
    if (isChrome || isFirefox) navigator.clipboard.writeText(link)
    else if (isIE) {
      navigator.clipboard.writeText(link)

      window.clipboardData.setData('Text', link);
      document.execCommand('copy');
    }

  }

  return (
    <div className={classes.root}>
    <div style={{backgroundColor:"black", color:'white'}}>
      <LinkIcon style={{paddingRight:10}}/>Clips Link
       <Button variant="contained" size="small" color="primary" className={classes.margin} onClick={copyLink}>
         Copy
        </Button>
    </div>
      <AppBar position="static">
      
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab  label="Standalone Players" {...a11yProps(0)} />
          <Tab   label="Embed" {...a11yProps(1)} />
         
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} style={{backgroundColor:'black',color:'white'}}>
      
          <FormControl component="fieldset">
      
            <RadioGroup aria-label="gender" name="gender1" value={linkType} onChange={handlePlayerChange}>
              <FormControlLabel value="shareppoint_SA" control={<Radio />} label="Sharepoint" />
             
            
              <FormControlLabel value="media_tmbobile" control={<Radio />} label="Media.T-Mobile.com" />
              <FormControlLabel value="magentaU_SA" control={<Radio />} label="Magenta U" />
               {audience==true && <FormControlLabel value="clips_SA" control={<Radio />} label="Clips.t-mobile.com/public" />}
            </RadioGroup>
           </FormControl>
           
         
                {/*
                <FormGroup row>
                      <FormControlLabel
                        control={<Checkbox checked={bGA} onChange={handleGAChange} name="Include Google Analytics" />}
                        label="Include Google Analytics"
                      />

                
                </FormGroup>
                
  */}
   <FormGroup row>
                <FormLabel component="legend">Accessbility<InfoOutlinedIcon onClick={()=>{openInfo("https://tmobileusa.sharepoint.com/sites/clips/SitePages/Accessibilty.aspx")}} style={{paddingLeft:"5px",color:"#E20074"}}/></FormLabel>
                <Grid container spacing={3}>
                  <Grid item xs={5}>
                  <FormControlLabel
                        control={<Checkbox checked={showCaption} onChange={handleToggleCaption} name="Show Caption" />}
                        label="Show Captions"
                      />
                  </Grid>
                  <Grid item xs={5}>
                  <FormControlLabel
                        control={<Checkbox checked={showControl} onChange={handleToggleControl} name="Show Control" />}
                        label="Show Controls"
                      />
                  </Grid>
                </Grid>
                    
               
          </FormGroup>
          <Grid container direction='column' justify="flex-end" >
              {bGA &&
              <Grid item>
                <TextField
                    id="outlined-size-small"
                    placeholder="GA Code"
                    variant="outlined"
                    disabled={!bGA}
                    value={GA}
                    size="small"
                    onChange={handelSetChangeGA}
                  />
               </Grid>
              }
               <Grid item>
                 <TextField
                    id="outlined-multiline-static"
                    multiline
                    rows={4}
                    variant="outlined"
                    value={link}
                    style={{width:'100%',paddingTop:20}}
                  />
              </Grid>
              <Box display="flex" flexDirection="row-reverse">   
               <Button  variant="contained" color="primary" onClick={copyVideoLink}  >
                  Copy Link
                </Button>
              </Box>
              
              
        </Grid>
        
       
      
      </TabPanel>
      <TabPanel value={value} index={1} style={{backgroundColor:'black',color:'white'}}>
        <FormControl component="fieldset">
      
            <RadioGroup aria-label="gender" name="gender1" value={linkType} onChange={handlePlayerChange}>
              <FormControlLabel value="sharepoint" control={<Radio />} label="Sharepoint" />
             
               <FormGroup row>
                {/*}
                <FormControlLabel value="lms" control={<Radio />} label="Sprint LMS" /> 
                      <FormControlLabel
                        control={<Checkbox disabled={bAllowSkip} checked={allowSkip} onChange={handleAlowSkip} name="Include Google Analytics" />}
                        label="Prevent Skipping"
                      />
            */}
                </FormGroup>
            
               
              <FormControlLabel value="c2" control={<Radio />} label="C2" />
            {/*  <FormControlLabel value="lessonly" control={<Radio />} label="Lessonly" /> */}
              <FormControlLabel value="magentaU" control={<Radio />} label="Magenta U" />
           {/*  {audience==true && <FormControlLabel value="clips" control={<Radio />} label="Clips.t-mobile.com/public" />}*/}
            </RadioGroup>
           </FormControl>

           <FormGroup row>
                <FormLabel component="legend">Accessbility<InfoOutlinedIcon onClick={()=>{openInfo("https://tmobileusa.sharepoint.com/sites/clips/SitePages/Accessibilty.aspx")}} style={{paddingLeft:"5px",color:"#E20074"}}/></FormLabel>
                <Grid container spacing={3}>
                  <Grid item xs={5}>
                  <FormControlLabel
                        control={<Checkbox checked={showCaption} onChange={handleToggleCaption} name="Show Caption" />}
                        label="Show Captions"
                      />
                  </Grid>
                  <Grid item xs={5}>
                  <FormControlLabel
                        control={<Checkbox checked={showControl} onChange={handleToggleControl} name="Show Control" />}
                        label="Show Controls"
                      />
                  </Grid>
                </Grid>
                    
                <Grid container spacing={3}>
               
                 
                      <Grid item xs={2}>
                    <Item>
                      </Item>    
                      </Grid>
                </Grid>
          </FormGroup>


           {(showTranscript && !isVerticalVideo)  &&
              <FormControl component="fieldset">
                <FormLabel component="legend">Transcript Layout</FormLabel>
                <RadioGroup row value={aspectType} onChange={handleAspectChange} aria-label="Transcript Layout" name="row-radio-buttons-group">
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <Item> <FormControlLabel value="16x9" control={<Radio />} label="16:9 iframe" /></Item>
                    <Item>  <img src={sixteenbynineIcon} style={{width:'100%'}}/></Item>
                  </Grid>
                  <Grid item xs={4}>
                    <Item>  <FormControlLabel value="24x9" control={<Radio />} label="24:9 iframe" /></Item>
                    <Item>  <img src={twentyfourynineIcon}style={{width:'100%'}}/></Item>
                  </Grid>
                  <Grid item xs xs={4}>
                    <Item>   <FormControlLabel value="1x1" control={<Radio />} label="1:1 iframe" /></Item>
                    <Item>  <img src={onebyoneIcon} style={{width:'100%'}}/></Item>
                  </Grid>
                </Grid>
                </RadioGroup>
              </FormControl>
            }
            {/*showTranscriptDefault &&
            <FormGroup row>
                    <FormControlLabel
                        control={<Checkbox checked={transcriptDefault} onChange={handleTransriptChange} name="Include Google Analytics" />}
                        label="Show transcript by default"
                      />
          </FormGroup>
          */}
          
           {/*
           <FormGroup row>
                    <FormControlLabel
                        control={<Checkbox checked={bGA} onChange={handleGAChange} name="Include Google Analytics" />}
                        label="Include Google Analytics"
                      />
          </FormGroup>
          */}
          
          <Grid container direction='column' justify="flex-end" >
           {bGA &&
              <Grid item>
                <TextField
                    id="outlined-size-small"
                    placeholder="GA Code"
                    variant="outlined"
                    disabled={!bGA}
                    value={GA}
                    size="small"
                    onChange={handelSetChangeGA}
                  />
               </Grid>
           }
               <Grid item>
                 <TextField
                    id="outlined-multiline-static"
                    multiline
                    rows={4}
                    variant="outlined"GA
                    value={link}
                    style={{width:'100%',paddingTop:20}}
                  />
              </Grid>
              
              <Box display="flex" flexDirection="row-reverse">   
               <Button  variant="contained" color="primary" onClick={copyVideoLink}  >
                  Copy embed code
                </Button>
              </Box>
              
        </Grid>
      </TabPanel>
   
    </div>
  );
}




const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: '#E20074',
    color: theme.palette.common.white,
    width: 500,
    maxWidth:500,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.common.white,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  const [open, setOpen] = React.useState(props.open)
  const [toggle, setToggle] = React.useState(false);
  let id = props.id
  let title = props.title
  let audience = props.audience
  const handleClose = () => {
    setOpen(false);
    props.close()
  };

  const handleOpen = () => {
    setOpen(true);

  };

  useEffect(() => {
    //  console.log(props.open)
    setOpen(props.open)
  }, [props.open]);


  return (
    <div >
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} style={{color: "white",backgroundColor:"black"}}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
         <ShareIcon/>Share Playlist
        </DialogTitle>
        <DialogContent dividers>
              <SimpleTabs id={id}  title ={title} audience={audience}/>
        </DialogContent>
        <DialogActions>
         
        </DialogActions>
      </Dialog>
    </div>
  );
}
